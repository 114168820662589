import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./styles/_global.scss";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import DataProvider from "./redux/store";
import "swiper/css";
import "./fonts/Icons/Linearicons.css";
import "antd/dist/antd.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<DataProvider>
			<App />
		</DataProvider>
	</BrowserRouter>
);
