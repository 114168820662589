import React from "react";
import { Route, Routes } from "react-router-dom";
import "./styles/main.scss";
import Templates from "./pages/Templates";
import Editor from "./pages/Editor";

function App() {
	return (
		<div className="app">
			<div className="main">
				<Routes>
					<Route path="/" element={<Templates />} />
					<Route path="/:pageId" element={<Editor />} />
				</Routes>
			</div>
		</div>
	);
}

export default App;
