import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import {
	getLang,
	getLangList,
} from "../../../../redux/selectors/editorSelectors";
import { setLang, setLangList } from "../../../../redux/reducers/editorReducer";
import { editorAPI } from "../../../../api/api";

const SettingLang = () => {
	const { Option } = Select;
	const lang = useSelector((state) => getLang(state));
	const langList = useSelector((state) => getLangList(state));

	const dispatch = useDispatch();

	const selectLang = (val) => {
		dispatch(setLang(val));
	};

	const getLanguages = async () => {
		const res = await editorAPI.getLanguages();
		if (res.api_success) {
			let langList = [];
			for (let item of res.list) {
				/*				if (item.is_active) { */
				langList.push(item.id);
				/*				} */
			}
			dispatch(setLangList(langList));
		}
	};
	useEffect(() => {
		getLanguages();
	}, []);

	return (
		<React.Fragment key="4">
			<Select
				defaultValue={lang}
				style={{
					width: 65,
				}}
				onChange={selectLang}
			>
				{langList.map((item, key) => (
					<React.Fragment key={key}>
						<Option value={item}>{item.toUpperCase()}</Option>
					</React.Fragment>
				))}
			</Select>
		</React.Fragment>
	);
};

export default SettingLang;
