import uuid from "react-uuid";
import vertical from "../assets/imagesBgBlock/vertical.jpg";
import horisontal from "../assets/imagesBgBlock/horisontal.jpg";
import fr1 from "../assets/imagesBgBlock/1fr.jpg";
import fr2 from "../assets/imagesBgBlock/2fr.jpg";
import fr3 from "../assets/imagesBgBlock/3fr.jpg";
import certificates_new from "../assets/certificates_new.jpg";

const langList = (languages) => {
	let result = {};
	for (let key of languages) {
		if (key === "ru") {
			result = { ...result, [key]: "КАКОЙ-ТО ТЕКСТ" };
		} else if (key === "en") {
			result = { ...result, [key]: "SOME TEXT" };
		} else if (key === "de") {
			result = { ...result, [key]: "ETWAS TEXT" };
		} else if (key === "ar") {
			result = { ...result, [key]: "بعض النصوص" };
		} else {
			result = { ...result, [key]: "КАКОЙ-ТО ТЕКСТ" };
		}
	}
	return result;
};
const langListSliced = (languages, id) => {
	let result = {};
	for (let key of languages) {
		result = { ...result, [key]: [sliced(key, id)] };
	}
	return result;
};
const getText = (lang) => {
	if (lang === "ru") {
		return "БЛОК ТЕКСТ";
	}
	if (lang === "en") {
		return "BLOCK TEXT";
	}
	if (lang === "de") {
		return "BLOCK TEXT";
	}
	if (lang === "ar") {
		return "حظر النص";
	}
	return "КАКОЙ-ТО ТЕКСТ";
};
/// ////////////////////////////////////
/// ////////////////////////////////////
/// ////Элементы четвертого уровня//////
export const sliced = (lang, id) => {
	return {
		name: "sliced",
		id: uuid(),
		textSliced: getText(lang),
		styles: {
			parentId: id,
			selfStyle: false,
			color: "#ffffff",
			colorDark: "#ffffff",
			fontFamily: "Futura PT",
			fontWeight: 300,
			fontStyle: "normal",
			fontSize: "20px",
		},
	};
};
/// ////////////////////////////////////
/// ////////////////////////////////////
/// ////Элементы третьего уровня////////
export const text = (languages) => {
	return {
		name: "text",
		id: uuid(),
		text: { ...langList(languages) },
		styles: {
			alignSelf: "center",
			margin: ["0px", "0px", "0px", "0px"],
			color: "#ffffff",
			colorDark: "#ffffff",
			fontFamily: "Futura PT",
			fontWeight: 300,
			fontStyle: "normal",
			fontSize: "20px",
			textAlign: "left",
		},
		animate: {
			stickyTextWithGradient: false,
		},
	};
};

export const textSticky = (languages) => {
	return {
		name: "text_sticky",
		id: uuid(),
		text: { ...langList(languages) },
		styles: {
			alignSelf: "center",
			margin: ["0px", "0px", "0px", "0px"],
			color: "#ffffff",
			colorDark: "#ffffff",
			invertedColor: "#000000",
			invertedColorDark: "#000000",
			fontFamily: "Futura PT",
			fontStyle: "normal",
			fontWeight: 300,
			fontSize: "20px",
			textAlign: "left",
		},
	};
};

export const textSliced = (languages) => {
	const id = uuid();
	return {
		name: "text_sliced",
		id,
		styles: {
			alignSelf: "center",
			margin: ["0px", "0px", "0px", "0px"],
			color: "#ffffff",
			colorDark: "#ffffff",
			fontStyle: "normal",
			fontFamily: "Futura PT",
			fontWeight: 300,
			fontSize: "20px",
		},
		...langListSliced(languages, id),
	};
};
export const blockTextBg = (languages) => {
	return {
		name: "block_text_bg",
		id: uuid(),
		text: { ...langList(languages) },
		styles: {
			alignSelf: "center",
			margin: ["0px", "0px", "0px", "0px"],
			padding: ["0px", "25px", "0px", "25px"],
			color: "#ffffff",
			colorDark: "#ffffff",
			background: "#000000",
			backgroundDark: "#000000",
			fontFamily: "Futura PT",
			fontStyle: "normal",
			fontWeight: 300,
			fontSize: "20px",
		},
	};
};

export const button_outline = (languages) => {
	return {
		name: "button_outline",
		id: uuid(),
		text: { ...langList(languages) },
		link: {
			isActive: true,
			out: false,
			path: "https://lichi.com/ru/ru/new",
			name: "new",
		},
		styles: {
			alignSelf: "center",
			margin: ["0px", "0px", "0px", "0px"],
			borderColor: "#ffffff",
			borderColorDark: "#ffffff",
			color: "#ffffff",
			colorDark: "#ffffff",
			fontStyle: "normal",
			fontFamily: "Futura PT",
			fontWeight: 300,
			fontSize: "20px",
		},
	};
};

export const buttonOutlineSticky = (languages) => {
	return {
		name: "button_outline_sticky",
		id: uuid(),
		text: { ...langList(languages) },
		link: {
			isActive: true,
			out: false,
			path: "https://lichi.com/ru/ru/new",
			name: "new",
		},
		styles: {
			alignSelf: "center",
			margin: ["0px", "0px", "0px", "0px"],
			invertedColor: "#000000",
			invertedColorDark: "#000000",
			color: "#ffffff",
			colorDark: "#ffffff",
			fontStyle: "normal",
			fontFamily: "Futura PT",
			fontWeight: 300,
			fontSize: "20px",
		},
	};
};

export const button_underline = (languages) => {
	return {
		name: "button_underline",
		id: uuid(),
		text: { ...langList(languages) },
		link: {
			isActive: true,
			out: false,
			path: "https://lichi.com/ru/ru/new",
			name: "new",
		},
		styles: {
			alignSelf: "center",
			margin: ["0px", "0px", "0px", "0px"],
			borderColorDark: "#000000",
			borderColor: "#000000",
			color: "#000000",
			colorDark: "#000000",
			fontStyle: "normal",
			fontFamily: "Futura PT",
			fontWeight: 400,
			fontSize: "20px",
		},
	};
};

export const countdownTimer = () => {
	let date = new Date();
	date.setDate(date.getDate() + 10);
	const monthNames = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"June",
		"July",
		"Aug",
		"Sept",
		"Oct",
		"Nov",
		"Dec",
	];
	const dateStr = `${`0${date.getDate()}`.slice(-2)} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;

	return {
		name: "countdown_timer",
		id: uuid(),
		datePiker: {
			date: dateStr,
			time: "00:00:00",
		},
		styles: {
			alignSelf: "center",
			margin: ["0px", "0px", "0px", "0px"],
			color: "#f64747",
			colorDark: "#f64747",
			fontFamily: "Futura PT",
			fontStyle: "normal",
			fontSizeTime: "50px",
			fontSizeType: "15px",
		},
	};
};

/// ////////////////////////////////////
/// ////////////////////////////////////
/// ////Элементы второго уровня/////////
export const blockImage = (ratio, url) => {
	return {
		id: uuid(),
		name: "block",
		type: "image",
		image: {
			url: !url ? vertical : url,
			path: "",
			...ratio,
		},

		isPlay: false,
		dash_src: "",
		video: "https://player.vimeo.com/progressive_redirect/playback/732365807/rendition/1080p/file.mp4?loc=external&signature=7cfecca5db8086cb6e8f13fc97dd7b504f0ac73ba819a24293c3233a21df8a25",
		hover: {
			hoverBackground: false,
			hoverText: false,
		},
		animate: {
			appearanceImage: false,
			appearanceImageIncrease: false,
		},
		link: {
			isActive: true,
			out: false,
			path: "https://lichi.com/ru/ru/new",
			name: "new",
		},
		blockSticky: false,
		isPaddingImage: false,
		styles: {
			justifyContent: "center",
			padding: ["0px", "0px", "0px", "0px"],
			background: "transparent",
			backgroundDark: "#181a1b",
		},
		components: [],
	};
};

export const blockSaintLaurentBlockImage = (ratio, url) => {
	return {
		id: uuid(),
		name: "block_outside",
		type: "image",
		image: {
			url: !url ? vertical : url,
			path: "",
			...ratio,
		},
		isPlay: false,
		dash_src: "",
		poster: "",
		video: "https://player.vimeo.com/progressive_redirect/playback/732365807/rendition/1080p/file.mp4?loc=external&signature=7cfecca5db8086cb6e8f13fc97dd7b504f0ac73ba819a24293c3233a21df8a25",
		hover: {
			hoverBackground: false,
			hoverText: false,
		},
		animate: {
			appearanceImage: false,
			appearanceImageIncrease: false,
		},
		link: {
			isActive: true,
			out: false,
			path: "https://lichi.com/ru/ru/new",
			name: "new",
		},
		blockSticky: false,
		isPaddingImage: false,
		styles: {
			justifyContent: "center",
			padding: ["0px", "0px", "0px", "0px"],
			background: "transparent",
			backgroundDark: "#181a1b",
		},
		components: [],
	};
};

export const blockStories = (ratio, url) => {
	return {
		id: uuid(),
		name: "block",
		type: "image",
		isStories: true,
		image: {
			url: !url ? vertical : url,
			path: "",
			...ratio,
		},
		isPlay: false,
		dash_src: "",
		video: "https://player.vimeo.com/progressive_redirect/playback/732365807/rendition/1080p/file.mp4?loc=external&signature=7cfecca5db8086cb6e8f13fc97dd7b504f0ac73ba819a24293c3233a21df8a25",
		hover: {
			hoverBackground: false,
			hoverText: false,
		},
		animate: {
			appearanceImage: false,
			appearanceImageIncrease: false,
		},
		blockSticky: false,
		isPaddingImage: false,
		styles: {
			justifyContent: "center",
			padding: ["0px", "0px", "0px", "0px"],
			background: "transparent",
			backgroundDark: "#181a1b",
		},
		components: [],
		stories: [],
	};
};

export const blockSix = (languages, ratio) => {
	return {
		id: uuid(),
		name: "block",
		image: {
			url: certificates_new,
			path: "",
			...ratio,
		},

		hover: {
			hoverBackground: false,
			hoverText: false,
		},
		link: {
			isActive: true,
			out: false,
			path: "https://lichi.com/ru/ru/new",
			name: "new",
		},
		styles: {
			justifyContent: "end",
		},
		components: [
			{
				name: "button_outline",
				id: uuid(),
				text: { ...langList(languages) },
				link: {
					isActive: true,
					out: false,
					path: "https://lichi.com/ru/ru/new",
					name: "new",
				},
				styles: {
					alignSelf: "center",
					margin: ["0px", "0px", "56px", "0px"],
					borderColor: "#000000",
					borderColorDark: "#000000",
					colorDark: "#000000",
					color: "#000000",
					fontFamily: "Futura PT",
					fontStyle: "normal",
					fontWeight: 300,
					fontSize: "20px",
				},
			},
		],
	};
};

export const blockCustomSize = (fr) => {
	let ratio = "";
	let url = "";
	if (fr === "1fr") {
		url = fr1;
		ratio = {
			ratioDesk: "2500x3500",
			ratioPhone: "2500x3500",
		};
	}
	if (fr === "2fr" || fr === "7fr") {
		url = fr2;
		ratio = {
			ratioDesk: "5000x3500",
			ratioPhone: "5000x3500",
		};
	}
	if (fr === "3fr") {
		url = fr3;
		ratio = {
			ratioDesk: "2143x3500",
			ratioPhone: "2143x3500",
		};
	}
	return blockImage(ratio, url);
};
/// ////////////////////////////////////
/// ////////////////////////////////////
/// ////Элементы первого уровня////////
export const swiperDesktop = (languages, ratio) => {
	return {
		id: uuid(),
		name: "swiper_desktop",
		image: {
			url: vertical,
			path: "",
			...ratio,
		},
		blockTheme: false,
		styles: {
			justifyContent: "end",
			marginBottom: "2px",
			padding: ["0px", "0px", "0px", "0px"],
			background: "transparent",
			backgroundDark: "#181a1b",
		},
		hover: {
			hoverBackground: false,
			hoverText: false,
		},
		link: {
			isActive: true,
			out: false,
			path: "https://lichi.com/ru/ru/new",
			name: "new",
		},
		colorDress: {
			visible: false,
			colors: [],
		},
		components: [
			{
				name: "text",
				id: uuid(),
				text: { ...langList(languages) },
				styles: {
					alignSelf: "start",
					margin: ["0px", "0px", "50px", "30px"],
					color: "#ffffff",
					colorDark: "#ffffff",
					fontFamily: "Futura PT",
					fontStyle: "normal",
					fontWeight: 300,
					fontSize: "20px",
				},
			},
			{
				name: "text",
				id: uuid(),
				text: { ...langList(languages) },
				styles: {
					alignSelf: "start",
					margin: ["0px", "0px", "50px", "30px"],
					colorDark: "#ffffff",
					fontFamily: "Futura PT",
					fontStyle: "normal",
					fontWeight: 300,
					fontSize: "20px",
				},
			},
		],
	};
};

export const swiperPhone = (ratio, isVertical) => {
	return {
		id: uuid(),
		name: "swiper_phone",
		blockTheme: false,
		image: {
			url: !isVertical ? horisontal : vertical,
			path: "",
			...ratio,
		},

		styles: {
			justifyContent: "center",
			marginBottom: "2px",
			padding: ["0px", "0px", "0px", "0px"],
			background: "transparent",
			backgroundDark: "#181a1b",
		},
		hover: {
			hoverBackground: false,
			hoverText: false,
		},
		link: {
			isActive: true,
			out: false,
			path: "https://lichi.com/ru/ru/new",
			name: "new",
		},
		colorDress: {
			visible: false,
			colors: [],
		},
		components: [],
	};
};

export const multiStories = (ratio, isVertical) => {
	return {
		id: uuid(),
		type: "stories",
		name: "swiper_phone",
		isStories: true,
		blockTheme: false,
		image: {
			url: !isVertical ? horisontal : vertical,
			path: "",
			...ratio,
		},
		styles: {
			justifyContent: "center",
			marginBottom: "2px",
			padding: ["0px", "0px", "0px", "0px"],
			background: "transparent",
			backgroundDark: "#181a1b",
		},
		hover: {
			hoverBackground: false,
			hoverText: false,
		},
		colorDress: {
			visible: false,
			colors: [],
		},
		components: [],
		stories: [],
	};
};

export const swiperHalf = (ratio) => {
	return {
		id: uuid(),
		name: "swiper_half",
		blockTheme: false,
		image: {
			url: horisontal,
			path: "",
			...ratio,
		},

		styles: {
			justifyContent: "center",
			marginBottom: "2px",
			padding: ["0px", "0px", "0px", "0px"],
			background: "transparent",
			backgroundDark: "#181a1b",
		},
		hover: {
			hoverBackground: false,
			hoverText: false,
		},
		link: {
			isActive: true,
			out: false,
			path: "https://lichi.com/ru/ru/new",
			name: "new",
		},
		colorDress: {
			visible: false,
			colors: [],
		},
		components: [],
	};
};
/// ////////////////////////////////////
/// ////////////////////////////////////
/// //////////Конструкторы//////////////

const gridTemplateColumns = (count) => {
	let result = [];
	for (let i = 0; i < count; i++) {
		result = [...result, "1fr"];
	}
	return result;
};
export const getInitialStyles = (name, count, languages) => {
	// count может быть массивом фракций

	let component = {};
	let blocks = [];
	let user = "all";
	let countries = {
		checked: ["all"],
		all: true,
	};

	if (name === "block-custom-size") {
		for (let fr of count) {
			let initial = blockCustomSize(fr);
			blocks = [...blocks, initial];
		}
	}

	if (name === "block-image") {
		const ratio = {
			ratioDesk: "3024x3780",
			ratioPhone: "3024x3780",
		};
		for (let i = 0; i < count; i++) {
			let initial = blockImage(ratio, vertical);
			blocks = [...blocks, initial];
		}
	}

	if (name === "block-image-saint") {
		const ratio = {
			ratioDesk: "3024x3780",
			ratioPhone: "3024x3780",
		};
		for (let i = 0; i < count; i++) {
			let initial = blockSaintLaurentBlockImage(ratio, vertical);
			blocks = [...blocks, initial];
		}
	}

	if (name === "stories-single") {
		const ratio = {
			ratioDesk: "3024x3780",
			ratioPhone: "3024x3780",
		};
		for (let i = 0; i < count; i++) {
			let initial = blockStories(ratio, vertical);
			blocks = [...blocks, initial];
		}
	}

	if (name === "swiper-half") {
		const ratio = {
			ratioDesk: "3024x3780",
			ratioPhone: "3024x3780",
		};
		for (let i = 0; i < count; i++) {
			let initial = blockImage(ratio, vertical);
			blocks = [...blocks, initial];
		}
	}

	if (name === "block-image-full-screen") {
		const ratio = {
			ratioDesk: "1920х1080",
			ratioPhone: "1080х1920",
		};
		for (let i = 0; i < count; i++) {
			let initial = blockImage(ratio, horisontal);
			blocks = [...blocks, initial];
		}
	}
	if (name === "block-image-w-half-h") {
		const ratio = {
			ratioDesk: "1920х540",
			ratioPhone: "1920х540",
		};
		for (let i = 0; i < count; i++) {
			let initial = blockSix(languages, ratio);
			blocks = [...blocks, initial];
		}
		countries = {
			checked: ["kz", "ru"],
			all: false,
		};
		user = "retail";
	}

	/// /////Переопределение имен////////
	if (name === "block-custom-size") {
		name = "block-image";
	}
	/// /////////////////////////////////

	component = {
		name,
		id: uuid(),
		components: blocks,
		blockTheme: false,
		styles: {
			gridGap: name === "block-image-saint" ? "2px" : "2px",
			gridTemplateColumns: Array.isArray(count)
				? count
				: gridTemplateColumns(count),
			marginBottom: "2px",
		},
		animate: {
			showFlash: true,
		},
		view: {
			user,
			countries,
		},
	};
	return component;
};

export const getInitialStylesSW = (name, count, languages) => {
	let component = {};
	let blocks = [];

	if (name === "block-slider") {
		const ratio = {
			ratioDesk: "3024x3780",
			ratioPhone: "3024x3780",
		};
		for (let i = 0; i < count; i++) {
			let initial = swiperDesktop(languages, ratio);
			blocks = [...blocks, initial];
		}
		component = {
			name: "block-slider",
			id: uuid(),
			blockTheme: false,
			components: blocks,
			slidePerView: 3.4,
			view: {
				wholesale: true,
				countries: {
					checked: ["all"],
					all: true,
				},
			},
			animate: {
				showFlash: true,
			},
			styles: {
				spaceBetween: 2,
				marginBottom: "2px",
			},
		};

		return component;
	}
	if (name === "block-slider-image") {
		const ratio = {
			ratioDesk: "3024x3780",
			ratioPhone: "3024x3780",
		};
		blocks = [blockImage(ratio, vertical)];
		for (let i = 0; i < count; i++) {
			let initial = swiperPhone(ratio, true);
			blocks = [...blocks, initial];
		}
		component = {
			name: "block-slider-image",
			id: uuid(),
			blockTheme: false,
			components: blocks,
			sliderPosition: "right",
			view: {
				user: "all",
				countries: {
					checked: ["all"],
					all: true,
				},
			},
			animate: {
				showFlash: true,
			},
			autoplay: {
				speed: 700,
				delay: 3000,
			},
			isProgressBar: false,
			isPagination: true,
			isAutoplay: true,
			isDarkPagination: false,
			styles: {
				spaceBetween: 2,
				marginBottom: "2px",
				padding: ["0px", "0px", "0px", "0px"],
				background: "transparent",
				backgroundDark: "#181a1b",
				gridGap: "2px",
				gridTemplateColumns: ["1fr", "1fr"],
			},
		};

		return component;
	}

	if (name === "block-slider-full") {
		const ratio = {
			ratioDesk: "1920х540",
			ratioPhone: "1920х540",
		};
		for (let i = 0; i < count; i++) {
			let initial = swiperPhone(ratio);
			blocks = [...blocks, initial];
		}
		component = {
			name: "block-slider-full",
			id: uuid(),
			blockTheme: false,
			components: blocks,
			view: {
				user: "all",
				countries: {
					checked: ["all"],
					all: true,
				},
			},
			animate: {
				showFlash: true,
			},
			autoplay: {
				speed: 700,
				delay: 3000,
			},
			isProgressBar: false,
			isPagination: true,
			isAutoplay: true,
			isDarkPagination: false,
			styles: {
				spaceBetween: 2,
				marginBottom: "2px",
				padding: ["0px", "0px", "0px", "0px"],
				background: "transparent",
				backgroundDark: "#181a1b",
			},
		};

		return component;
	}

	if (name === "saint-laurent-slider-full" || name === "saint-slider-full") {
		const ratio = {
			ratioDesk: "1920х540",
			ratioPhone: "1920х540",
		};
		for (let i = 0; i < count; i++) {
			let initial = swiperPhone(ratio);
			blocks = [...blocks, initial];
		}
		component = {
			name: "saint-slider-full",
			id: uuid(),
			blockTheme: false,
			components: blocks,
			view: {
				user: "all",
				countries: {
					checked: ["all"],
					all: true,
				},
			},
			animate: {
				showFlash: true,
			},
			autoplay: {
				speed: 700,
				delay: 3000,
			},
			isProgressBar: false,
			isPagination: true,
			isAutoplay: true,
			isDarkPagination: false,
			styles: {
				spaceBetween: 2,
				marginBottom: "2px",
				padding: ["0px", "0px", "0px", "0px"],
				background: "transparent",
				backgroundDark: "#181a1b",
			},
		};

		return component;
	}

	if (name === "block-slider-category-v2") {
		const ratio = {
			ratioDesk: "1920х540",
			ratioPhone: "1920х540",
		};
		for (let i = 0; i < count; i++) {
			let initial = swiperPhone(ratio);
			blocks = [...blocks, initial];
		}
		component = {
			name: "block-slider-category-v2",
			id: uuid(),
			blockTheme: false,
			view: {
				user: "all",
				countries: {
					checked: ["all"],
					all: true,
				},
			},
			animate: {
				showFlash: true,
			},
			autoplay: {
				speed: 700,
				delay: 3000,
			},
			nameStyles: {
				margin: ["0px", "0px", "0px", "0px"],
				color: "#000000",
				colorDark: "#ffffff",
				fontFamily: "Futura PT",
				fontWeight: 300,
				fontStyle: "normal",
				fontSize: "20px",
				textAlign: "left",
			},
			priceStyles: {
				margin: ["0px", "0px", "0px", "0px"],
				color: "#000000",
				colorDark: "#ffffff",
				fontFamily: "Futura PT",
				fontWeight: 300,
				fontStyle: "normal",
				fontSize: "16px",
				textAlign: "left",
			},
			category: "new",
			count: 11,
			shop: 1,
			lang: 1,
			isPagination: false,
			isAutoplay: false,
			isDarkPagination: false,
			slidePerView: 4,
			slidePerGroup: 4,
			styles: {
				spaceBetween: 2,
				marginBottom: "2px",
				padding: ["0px", "0px", "0px", "0px"],
				background: "transparent",
				backgroundDark: "#181a1b",
			},
		};

		return component;
	}

	if (name === "stories-multi") {
		const ratio = {
			ratioDesk: "1920х540",
			ratioPhone: "1920х540",
		};
		for (let i = 0; i < count; i++) {
			let initial = multiStories(ratio);
			blocks = [...blocks, initial];
		}
		component = {
			name: "stories-multi",
			id: uuid(),
			blockTheme: false,
			components: blocks,
			view: {
				user: "all",
				countries: {
					checked: ["all"],
					all: true,
				},
			},
			animate: {
				showFlash: true,
			},
			autoplay: {
				speed: 700,
				delay: 3000,
			},
			isProgressBar: false,
			isPagination: true,
			isAutoplay: true,
			isDarkPagination: false,
			styles: {
				spaceBetween: 2,
				marginBottom: "2px",
				padding: ["0px", "0px", "0px", "0px"],
				background: "transparent",
				backgroundDark: "#181a1b",
			},
		};

		return component;
	}

	if (name === "block-video") {
		const ratio = {
			ratioDesk: "1920х1080",
			ratioPhone: "1080х1920",
		};
		component = {
			name: "block-video",
			id: uuid(),
			blockTheme: false,
			isPlay: false,
			dash_src: "",
			video: "https://player.vimeo.com/progressive_redirect/playback/732365807/rendition/1080p/file.mp4?loc=external&signature=7cfecca5db8086cb6e8f13fc97dd7b504f0ac73ba819a24293c3233a21df8a25",
			image: {
				url: "https://i.vimeocdn.com/video/1473820692-063b7eb8b4dd16415f043d07301a8babb370b30c4d1f95385363e5c4a4d19ae8-d_1920",
				path: "",
				...ratio,
			},

			link: {
				isActive: true,
				out: false,
				path: "https://lichi.com/ru/ru/new",
				name: "new",
			},
			view: {
				user: "all",
				countries: {
					checked: ["all"],
					all: true,
				},
			},
			animate: {
				showFlash: true,
			},
			components: [
				{
					id: uuid(),
					name: "block",
					hover: {
						hoverText: false,
					},
					styles: {
						justifyContent: "center",
					},
					components: [],
				},
			],
			styles: {
				marginBottom: "2px",
			},
		};

		return component;
	}
};
