import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import styled from "../../../../styles/editorPage/rightBarEditor.module.scss";

const InputText = ({ changeParams, item, isReplacement, updateCallback }) => {
	return (
		<Form.Item
			style={{ marginBottom: "5px" }}
			name={item[0]}
			label={item[0]}
			rules={[
				{
					required: true,
				},
			]}
		>
			<Input
				defaultValue={item[1]}
				size="small"
				type="text"
				onChange={(e) => {
					if (changeParams)
						changeParams(
							"text",
							{ [item[0]]: e.target.value },
							isReplacement
						);
					if (updateCallback)
						updateCallback({ [item[0]]: e.target.value });
				}}
			/>
		</Form.Item>
	);
};

const SettingText = ({
	text,
	changeParams,
	name,
	isReplacement,
	updateCallback,
}) => {
	const layout = {
		labelCol: {
			span: 3,
		},
		wrapperCol: {
			span: 21,
		},
	};

	const [data, setData] = useState(text || {});

	useEffect(() => {
		setData(text);
	}, [text]);

	return (
		<div>
			{text ? (
				<div>
					<div className={styled.textI18n}>
						<h3>Текст</h3>
						<Form
							{...layout}
							style={{ width: "300px", margin: "0 15px" }}
						>
							{Object.entries(data || {}).map((item, key) => (
								<React.Fragment key={key}>
									<InputText
										changeParams={changeParams}
										updateCallback={updateCallback}
										item={item}
										isReplacement={isReplacement}
									/>
								</React.Fragment>
							))}
						</Form>
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

export default SettingText;
