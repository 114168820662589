import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "antd";
import styled from "../../styles/modals/modals.module.scss";
import { setComponentCollection } from "../../redux/reducers/editorReducer";
import { getInitialStyles } from "../../helpers/initialStylesForComponents";
import i30_70 from "../../assets/TemplatesCustomSize/30_70.png";
import i70_30 from "../../assets/TemplatesCustomSize/70_30.png";
import i25_25_50 from "../../assets/TemplatesCustomSize/25_25_50.png";
import i25_50_25 from "../../assets/TemplatesCustomSize/25_50_25.png";
import i50_25_25 from "../../assets/TemplatesCustomSize/50_25_25.png";

export default function ModalCustomSize({ open, handleClose, name }) {
	const dispatch = useDispatch();

	const createNewComponent = (name) => {
		handleClose();
		const getArrayFr = (name) => {
			if (name === "i30_70") return ["3fr", "7fr"];
			if (name === "i70_30") return ["7fr", "3fr"];
			if (name === "i50_25_25") return ["2fr", "1fr", "1fr"];
			if (name === "i25_50_25") return ["1fr", "2fr", "1fr"];
			if (name === "i25_25_50") return ["1fr", "1fr", "2fr"];
		};
		let component = getInitialStyles("block-custom-size", getArrayFr(name));
		dispatch(setComponentCollection(component));
	};

	return (
		<div>
			<Modal
				visible={open}
				onCancel={handleClose}
				title={name}
				closeIcon={<span className="f-icon icon-cross fs-17" />}
				footer={[
					<div className={styled.footerModalCustom}>
						выберите тип компонента
					</div>,
				]}
			>
				<div
					className={styled.itemModalCustom}
					onClick={() => createNewComponent("i30_70")}
				>
					<img src={i30_70} alt="i30_70" />
				</div>
				<div
					className={styled.itemModalCustom}
					onClick={() => createNewComponent("i70_30")}
				>
					<img src={i70_30} alt="i70_30" />
				</div>
				<div
					className={styled.itemModalCustom}
					onClick={() => createNewComponent("i25_25_50")}
				>
					<img src={i25_25_50} alt="i25_25_50" />
				</div>
				<div
					className={styled.itemModalCustom}
					onClick={() => createNewComponent("i25_50_25")}
				>
					<img src={i25_50_25} alt="i25_50_25" />
				</div>
				<div
					className={styled.itemModalCustom}
					onClick={() => createNewComponent("i50_25_25")}
				>
					<img src={i50_25_25} alt="i50_25_25" />
				</div>
			</Modal>
		</div>
	);
}
