import { editorAPI } from "../../api/api";
import {
	setPageType,
	setPaginationData,
	setTemplateList,
} from "../reducers/templatesReducer";

export const setTemplates = (page) => {
	return async (dispatch) => {
		const res = await editorAPI.getList(page);
		dispatch(setTemplateList(res.data));
		dispatch(setPaginationData(res.pagination));
	};
};

export const getPageType = (value) => {
	return (dispatch) => {
		dispatch(setPageType(value));
	};
};
