import React from "react";
import { Select } from "antd";
import { Option } from "antd/es/mentions";
import styled from "../../../../styles/editorPage/rightBarEditor.module.scss";

const SettingTypeBlock = ({ type, changeParams, isReplacement }) => {
	const changeType = (value) => {
		changeParams("type", value, isReplacement);
	};

	return (
		<>
			{type ? (
				<div>
					<div className={styled.textAndInput}>
						<div className={styled.label}>Тип блока:</div>
						<Select
							style={{
								width: 350,
							}}
							defaultValue={type}
							onChange={changeType}
							getPopupContainer={(trigger) => trigger.parentNode}
						>
							<Option value="video">Видео</Option>
							<Option value="image">Изображение</Option>
						</Select>
					</div>
				</div>
			) : null}
		</>
	);
};

export default SettingTypeBlock;
