import React from "react";
import BorderActive from "../../../workFields/BorderActive";
import { useBorderActive } from "../../../../../hooks/useBorderActive";

const Sliced = ({
	component,
	componentSP,
	mainId,
	blockId,
	elemId,
	isActivePage,
}) => {
	const { width, height, isHide, ref, resize, idActive, selectThisComponent } =
		useBorderActive(component, [mainId, blockId, elemId, component.id]);

	const select = (e) => {
		resize();
		selectThisComponent(e);
	};

	return (
		<span className="p-relative">
			{!isHide &&
				idActive.length === 4 &&
				component.id === idActive[3] &&
				!isActivePage && (
					<BorderActive
						width={width}
						height={height}
						id={[mainId, blockId, elemId, component.id]}
						large
					/>
				)}
			<span
				ref={ref}
				style={
					component?.styles?.selfStyle
						? {
								color: component.styles.color,
								fontFamily: component.styles.fontFamily,
								fontWeight: component.styles.fontWeight,
								fontSize: component.styles.fontSize,
								fontStyle: component.styles.fontStyle || "normal",
							}
						: {}
				}
				onClick={select}
			>
				{component.textSliced}
			</span>
		</span>
	);
};

export default Sliced;
