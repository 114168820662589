import React from "react";
import { Checkbox } from "antd";
import styled from "../../../../styles/editorPage/rightBarEditor.module.scss";

/**
 * @author Zholaman Zhumanov
 * @created 17.07.2024
 * @description
 * @last-updated
 * @update-description
 * @todo
 * @fixme
 * @param props
 * @constructor
 */
const SettingPaginationSwiper = (props) => {
	const { isPagination, isDarkPagination, changeParams, isReplacement } =
		props;

	return (
		<>
			{isPagination !== undefined ? (
				<>
					<div
						className={styled.rightStylesMainMargin}
						key={isPagination}
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<div style={{ fontWeight: 500 }}>Пагинация для слайдера</div>
						<div className={styled.checkbox}>
							<Checkbox
								checked={isPagination}
								onChange={(e) =>
									changeParams(
										"isPagination",
										e.target.checked,
										isReplacement
									)
								}
							>
								Показать
							</Checkbox>
						</div>
					</div>

					<div
						className={styled.rightStylesMainMargin}
						key={isDarkPagination}
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<div style={{ fontWeight: 500 }}>Темные точки</div>
						<div className={styled.checkbox}>
							<Checkbox
								checked={isDarkPagination}
								onChange={(e) =>
									changeParams(
										"isDarkPagination",
										e.target.checked,
										isReplacement
									)
								}
							>
								{isDarkPagination ? "Темный" : "Светлый"}
							</Checkbox>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

export default SettingPaginationSwiper;
