import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import {
	setBlock,
	setBlockSwiper,
	setElement,
	setSliced,
} from "../../../../redux/reducers/editorReducer";
import {
	blockImage,
	blockTextBg,
	button_outline,
	button_underline,
	buttonOutlineSticky,
	countdownTimer,
	sliced,
	swiperDesktop,
	swiperPhone,
	text,
	textSliced,
	textSticky,
} from "../../../../helpers/initialStylesForComponents";
import styled from "../../../../styles/editorPage/rightBarEditor.module.scss";
import {
	getLang,
	getLangList,
} from "../../../../redux/selectors/editorSelectors";

const AddComponent = (props) => {
	const { name, isReplacement } = props;

	const langList = useSelector((state) => getLangList(state));
	const lang = useSelector((state) => getLang(state));

	const dispatch = useDispatch();

	const addBlock = () => {
		dispatch(setBlock(blockImage()));
	};
	const addBlockSwiper = () => {
		dispatch(setBlockSwiper(swiperDesktop()));
	};
	const addBlockSwiperPhone = () => {
		dispatch(setBlockSwiper(swiperPhone()));
	};
	const addElement = (element) => {
		dispatch(setElement(element));
	};
	const addSliced = () => {
		dispatch(setSliced(sliced(lang), isReplacement));
	};

	return (
		<div>
			{name === "block-image" ? (
				<div className={styled.textAndSwitch}>
					<div className={styled.label}>Добавить блок:</div>
					<div className={styled.inputWrapper}>
						<div className={`${styled.buttomItems}`}>
							<Button
								variant="outlined"
								size="small"
								color="primary"
								onClick={addBlock}
							>
								<span className="icon-plus fs-11" />
							</Button>
						</div>
					</div>
				</div>
			) : name === "block-slider" ? (
				<div className={styled.textAndSwitch}>
					<div className={styled.label}>Добавить блок:</div>
					<div className={styled.inputWrapper}>
						<div className={`${styled.buttomItems}`}>
							<Button
								variant="outlined"
								size="small"
								color="primary"
								onClick={addBlockSwiper}
							>
								<span className="icon-plus fs-11" />
							</Button>
						</div>
					</div>
				</div>
			) : name === "text_sliced" ? (
				<div className={styled.textAndSwitch}>
					<div className={styled.label}>Добавить слово:</div>
					<div className={styled.inputWrapper}>
						<div className={`${styled.buttomItems}`}>
							<Button
								variant="outlined"
								size="small"
								color="primary"
								onClick={addSliced}
							>
								<span className="icon-plus fs-11" />
							</Button>
						</div>
					</div>
				</div>
			) : name === "block-slider-full" ? (
				<div className={styled.textAndSwitch}>
					<div className={styled.label}>Добавить блок:</div>
					<div>
						<div className={`${styled.buttomItems}`}>
							<Button
								variant="outlined"
								size="small"
								color="primary"
								onClick={addBlockSwiperPhone}
							>
								<span className="icon-plus fs-11" />
							</Button>
						</div>
					</div>
				</div>
			) : name === "block" ||
			  name === "swiper_desktop" ||
			  name === "swiper_phone" ||
			  name === "stories" ||
			  name === "block_outside" ? (
				<div>
					<div className={styled.textAndSwitch}>
						<div className={styled.label}>Добавить элемент:</div>
					</div>
					<div className={`${styled.buttomItemsC}`}>
						<Button
							style={{ marginRight: "10px", marginTop: "10px" }}
							variant="outlined"
							color="primary"
							size="small"
							onClick={() => addElement(text(langList))}
						>
							<div className={styled.inner}>
								<div className={styled.innerText}> текст</div>
								<span
									style={{ paddingTop: "3px" }}
									className="icon-text-format fs-13"
								/>
							</div>
						</Button>
						<Button
							style={{ marginRight: "10px", marginTop: "10px" }}
							variant="outlined"
							color="primary"
							size="small"
							onClick={() => addElement(button_outline(langList))}
						>
							<div className={styled.inner}>
								<div className={styled.innerText}> кнопка</div>
								<span
									style={{ paddingTop: "3px" }}
									className="icon-power-switch fs-15"
								/>
							</div>
						</Button>
						{name === "block" && (
							<>
								<Button
									style={{
										marginRight: "10px",
										marginTop: "10px",
									}}
									variant="outlined"
									color="primary"
									size="small"
									onClick={() => addElement(textSticky(langList))}
								>
									<div className={styled.inner}>
										<div className={styled.innerText}>
											{" "}
											текст (sticky)
										</div>
										<span
											style={{ paddingTop: "3px" }}
											className="icon-text-format fs-13"
										/>
									</div>
								</Button>
								<Button
									style={{
										marginRight: "10px",
										marginTop: "10px",
									}}
									variant="outlined"
									color="primary"
									size="small"
									onClick={() =>
										addElement(buttonOutlineSticky(langList))
									}
								>
									<div className={styled.inner}>
										<div className={styled.innerText}>
											{" "}
											кнопка (sticky)
										</div>
										<span
											style={{ paddingTop: "3px" }}
											className="icon-power-switch fs-15"
										/>
									</div>
								</Button>
							</>
						)}
						<Button
							style={{ marginRight: "10px", marginTop: "10px" }}
							variant="outlined"
							color="primary"
							size="small"
							onClick={() => addElement(button_underline(langList))}
						>
							<div className={styled.inner}>
								<div className={styled.innerText}>
									{" "}
									кнопка с нижней рамкой
								</div>
								<span
									style={{ paddingTop: "3px" }}
									className="icon-power-switch fs-15"
								/>
							</div>
						</Button>
						<Button
							style={{ marginRight: "10px", marginTop: "10px" }}
							variant="outlined"
							color="primary"
							size="small"
							onClick={() => addElement(countdownTimer())}
						>
							<div className={styled.inner}>
								<div className={styled.innerText}>таймер</div>
								<span
									style={{ paddingTop: "3px" }}
									className="icon-clock fs-15"
								/>
							</div>
						</Button>
						<Button
							style={{ marginRight: "10px", marginTop: "10px" }}
							variant="outlined"
							color="primary"
							size="small"
							onClick={() => addElement(blockTextBg(langList))}
						>
							<div className={styled.inner}>
								<div className={styled.innerText}>текст с фоном</div>
								<span
									style={{ paddingTop: "3px" }}
									className="icon-text-format fs-13"
								/>
							</div>
						</Button>
						<Button
							style={{ marginRight: "10px", marginTop: "10px" }}
							variant="outlined"
							color="primary"
							size="small"
							onClick={() => addElement(textSliced(langList))}
						>
							<div className={styled.inner}>
								<div className={styled.innerText}>текст по блокам</div>
								<span
									style={{ paddingTop: "3px" }}
									className="icon-text-format fs-13"
								/>
							</div>
						</Button>
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

export default AddComponent;
