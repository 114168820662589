const getResult = (collection, activeId, itemForChanged) => {
	let result = [];
	for (let item of collection) {
		if (item.id === activeId) {
			result = [...result, itemForChanged];
		} else {
			result = [...result, item];
		}
	}
	return result;
};

export const addNewStyle = (
	componentCollection,
	id,
	currentLang,
	type,
	params,
	replacement,
	replacementId
) => {
	let firstLevel = componentCollection.filter(
		(item) => item.id === id[0]
	)?.[0];
	let twoLevelComponents = firstLevel.components;
	let twoLevel = twoLevelComponents?.filter((item) => item.id === id[1])[0];
	let threeLevelComponents = twoLevel?.components;
	let threeLevel = threeLevelComponents?.filter(
		(item) => item.id === id[2]
	)[0];
	let fourLevelComponents = threeLevel && threeLevel[currentLang];
	let fourLevel = fourLevelComponents?.filter((item) => item.id === id[3])[0];
	let threeLevelComponentsReplacement = twoLevel?.componentsReplacement;
	let threeLevelReplacement = threeLevelComponentsReplacement?.filter(
		(item) => item.id === (replacementId ? replacementId[2] : id[2])
	)[0];
	let fourLevelComponentsReplacement =
		threeLevelReplacement && threeLevelReplacement[currentLang];
	let fourLevelReplacement = fourLevelComponentsReplacement?.filter(
		(item) => item.id === (replacementId ? replacementId[3] : id[3])
	)[0];

	// Проверка на паддинги
	if (id.length === 2 && params.padding) {
		let res = false;
		params.padding.forEach((i) => {
			i !== "0px" && (res = true);
		});
		twoLevel.isPaddingImage = res;
	}

	const selectProperty = (level) => {
		if (
			type === "styles" ||
			type === "image" ||
			type === "stories" ||
			type === "text" ||
			type === "datePiker" ||
			type === "link" ||
			type === "view" ||
			type === "colorDress" ||
			type === "hover" ||
			type === "animate" ||
			type === "datePikerReplacement" ||
			type === "datePikerDelete" ||
			type === "autoplay" ||
			type === "nameStyles" ||
			type === "priceStyles"
		) {
			if (type === "stories") {
				return {
					...level,
					[type]: [...params],
				};
			}
			return {
				...level,
				[type]: {
					...level[type],
					...params,
				},
			};
		}
		if (
			type === "count" ||
			type === "category" ||
			type === "video" ||
			type === "dash_src" ||
			type === "textSliced" ||
			type === "isPreview" ||
			type === "deadline" ||
			type === "type" ||
			type === "isPlay" ||
			type === "isProgressBar" ||
			type === "isPagination" ||
			type === "isAutoplay" ||
			type === "datePikerDelete" ||
			type === "blockTheme" ||
			type === "sliderPosition" ||
			type === "slidePerGroup" ||
			type === "slidePerView" ||
			type === "isDarkPagination"
		) {
			return {
				...level,
				[type]: params,
			};
		}
		if (type === "lang" || type === "shop") {
			return {
				...level,
				...params,
			};
		}
	};

	if (replacement) {
		const isFourLevel = () => {
			if ((replacementId && replacementId[3]) || id[3]) {
				return {
					...threeLevelReplacement,
					[currentLang]: getResult(
						fourLevelComponentsReplacement,
						(replacementId && replacementId[3]) || id[3],
						selectProperty(fourLevelReplacement)
					),
				};
			}
			return selectProperty(threeLevelReplacement);
		};
		return getResult(
			componentCollection,
			replacementId ? replacementId[0] : id[0],
			{
				...firstLevel,
				components: getResult(
					twoLevelComponents,
					replacementId ? replacementId[1] : id[1],
					{
						...twoLevel,
						componentsReplacement: getResult(
							threeLevelComponentsReplacement,
							replacementId ? replacementId[2] : id[2],
							isFourLevel()
						),
					}
				),
			}
		);
	}

	if (id?.length === 1) {
		return getResult(componentCollection, id[0], selectProperty(firstLevel));
	}
	if (id?.length === 2) {
		return getResult(componentCollection, id[0], {
			...firstLevel,
			components: getResult(
				twoLevelComponents,
				id[1],
				selectProperty(twoLevel)
			),
		});
	}
	if (id?.length === 3) {
		return getResult(componentCollection, id[0], {
			...firstLevel,
			components: getResult(twoLevelComponents, id[1], {
				...twoLevel,
				components: getResult(
					threeLevelComponents,
					id[2],
					selectProperty(threeLevel)
				),
			}),
		});
	}

	if (id?.length === 4) {
		return getResult(componentCollection, id[0], {
			...firstLevel,
			components: getResult(twoLevelComponents, id[1], {
				...twoLevel,
				components: getResult(threeLevelComponents, id[2], {
					...threeLevel,
					[currentLang]: getResult(
						fourLevelComponents,
						id[3],
						selectProperty(fourLevel)
					),
				}),
			}),
		});
	}
};

export const deleteComponent = (componentCollection, id, currentLang) => {
	let itemForChanged = {};

	let firstLevel = componentCollection.filter((item) => item.id === id[0])[0];
	let twoLevelComponents = firstLevel?.components;
	let twoLevel = twoLevelComponents?.filter((item) => item.id === id[1])[0];
	let threeLevelComponents = twoLevel?.components;
	let threeLevel = threeLevelComponents?.filter(
		(item) => item.id === id[2]
	)[0];
	let fourLevelComponents = threeLevel && threeLevel[currentLang];
	let threeLevelClear = threeLevelComponents?.filter(
		(item) => item.id !== id[2]
	);
	let fourLevelClear = fourLevelComponents?.filter(
		(item) => item.id !== id[3]
	);

	let threeLevelComponentsReplacement = twoLevel?.componentsReplacement;
	let threeLevelClearReplacement = threeLevelComponentsReplacement?.filter(
		(item) => item.id !== id[2]
	);

	if (
		id?.length === 1 ||
		(id?.length === 2 && twoLevelComponents.length === 1)
	) {
		return componentCollection.filter((item) => item.id !== id[0]);
	}
	if (id?.length === 2) {
		let newTwoLevelComponents = [];
		let newFirstLevel = {};
		let key = 0;
		for (let item of twoLevelComponents) {
			if (item.id !== id[1]) {
				newTwoLevelComponents = [...newTwoLevelComponents, item];
				key++;
			} else if (
				firstLevel.name === "block-image" ||
				firstLevel.name === "block-image-w-half-h" ||
				firstLevel.name === "block-image-full-screen"
			) {
				let newGridTemplateColumns = [
					...firstLevel.styles.gridTemplateColumns,
				];
				newGridTemplateColumns.splice(key, 1);
				newFirstLevel = {
					...firstLevel,
					styles: {
						...firstLevel.styles,
						gridTemplateColumns: newGridTemplateColumns,
					},
				};
			}
		}
		itemForChanged = {
			...newFirstLevel,
			components: newTwoLevelComponents,
		};
		let result = [];
		for (let item of componentCollection) {
			if (item.id === id[0]) {
				result = [...result, itemForChanged];
			} else {
				result = [...result, item];
			}
		}
		return result;
	}
	if (id?.length === 3) {
		return getResult(componentCollection, id[0], {
			...firstLevel,
			components: getResult(twoLevelComponents, id[1], {
				...twoLevel,
				components: threeLevelClear,
				componentsReplacement: threeLevelClearReplacement,
			}),
		});
	}

	if (id?.length === 4) {
		return getResult(componentCollection, id[0], {
			...firstLevel,
			components: getResult(twoLevelComponents, id[1], {
				...twoLevel,
				components: !fourLevelClear.length
					? threeLevelClear
					: getResult(threeLevelComponents, id[2], {
							...threeLevel,
							[currentLang]: fourLevelClear,
						}),
			}),
		});
	}
};

export const deleteBlockSwiper = (componentCollection, id) => {
	let firstLevel = componentCollection.filter((item) => item.id === id[0])[0];
	let twoLevelComponents = firstLevel?.components;
	let twoLevelClear = twoLevelComponents?.filter((item) => item.id !== id[1]);
	return getResult(componentCollection, id[0], {
		...firstLevel,
		components: twoLevelClear,
	});
};

export const deleteKeyObject = (componentCollection, id, key) => {
	let firstLevel = componentCollection.filter((item) => item.id === id[0])[0];
	let twoLevelComponents = firstLevel?.components;
	let twoLevel = twoLevelComponents?.filter((item) => item.id === id[1])[0];
	let threeLevelComponents = twoLevel?.components;
	let threeLevel = threeLevelComponents?.filter(
		(item) => item.id === id[2]
	)[0];
	if (id?.length === 3) {
		delete threeLevel[key];

		return getResult(componentCollection, id[0], {
			...firstLevel,
			components: getResult(twoLevelComponents, id[1], {
				...twoLevel,
				components: getResult(threeLevelComponents, id[2], {
					...threeLevel,
				}),
			}),
		});
	}
};

export const addBlock = (componentCollection, id, block) => {
	let itemForChanged = {};
	let firstLevel = componentCollection.filter((item) => item.id === id[0])[0];
	let newGridTemplateColumns = [
		...firstLevel.styles.gridTemplateColumns,
		"1fr",
	];
	let newComponentsTwoLevel = [...firstLevel.components, block];
	itemForChanged = {
		...firstLevel,
		components: newComponentsTwoLevel,
		styles: {
			...firstLevel.styles,
			gridTemplateColumns: newGridTemplateColumns,
		},
	};

	let result = [];
	for (let item of componentCollection) {
		if (item.id === id[0]) {
			result = [...result, itemForChanged];
		} else {
			result = [...result, item];
		}
	}
	return result;
};

export const addBlockSwiper = (componentCollection, id, block) => {
	let firstLevel = componentCollection.filter((item) => item.id === id[0])[0];
	let twoLevelComponents = firstLevel.components;

	return getResult(componentCollection, id[0], {
		...firstLevel,
		components: [...twoLevelComponents, block],
	});
};

export const addElement = (componentCollection, id, element) => {
	const sticky = ["text_sticky", "button_outline_sticky"];

	let firstLevel = componentCollection.filter((item) => item.id === id[0])[0];
	let twoLevelComponents = firstLevel.components;
	let twoLevel = twoLevelComponents.filter((item) => item.id === id[1])[0];
	let threeLevelComponents = twoLevel?.components;
	if (sticky.includes(element.name)) {
		threeLevelComponents = threeLevelComponents.filter((item) =>
			sticky.includes(item.name)
		);
		twoLevel.blockSticky = true;
	} else {
		threeLevelComponents = threeLevelComponents.filter(
			(item) => !sticky.includes(item.name)
		);
		twoLevel.blockSticky = false;
	}

	return getResult(componentCollection, id[0], {
		...firstLevel,
		components: getResult(twoLevelComponents, id[1], {
			...twoLevel,
			components: [...threeLevelComponents, element],
		}),
	});
};

export const addElementReplacement = (componentCollection, id, element) => {
	let firstLevel = componentCollection.filter((item) => item.id === id[0])[0];
	let twoLevelComponents = firstLevel.components;
	let twoLevel = twoLevelComponents.filter((item) => item.id === id[1])[0];

	let threeLevelComponentsReplacement = twoLevel?.componentsReplacement;
	let filter = threeLevelComponentsReplacement?.filter(
		(item) => item.id !== id[2]
	);

	return getResult(componentCollection, id[0], {
		...firstLevel,
		components: getResult(
			twoLevelComponents,
			id[1],
			element
				? filter
					? {
							...twoLevel,
							componentsReplacement: [...filter, element],
						}
					: { ...twoLevel, componentsReplacement: [element] }
				: filter
					? { ...twoLevel, componentsReplacement: [...filter] }
					: { ...twoLevel, componentsReplacement: [] }
		),
	});
};

export const addSliced = (
	componentCollection,
	id,
	sliced,
	currentLang,
	isReplacement
) => {
	let firstLevel = componentCollection.filter((item) => item.id === id[0])[0];
	let twoLevelComponents = firstLevel.components;
	let twoLevel = twoLevelComponents?.filter((item) => item.id === id[1])[0];
	let threeLevelComponents = twoLevel?.components;
	let threeLevel = threeLevelComponents?.filter(
		(item) => item.id === id[2]
	)[0];
	let fourLevelComponents = threeLevel && threeLevel[currentLang];

	let threeLevelComponentsReplacement = twoLevel?.componentsReplacement;
	let threeLevelReplacement = threeLevelComponentsReplacement?.filter(
		(item) => item.id === id[2]
	)[0];
	let fourLevelComponentsReplacement =
		threeLevelReplacement && threeLevelReplacement[currentLang];

	const getIsReplacement = () => {
		if (!isReplacement) {
			return {
				components: getResult(threeLevelComponents, id[2], {
					...threeLevel,
					[currentLang]: [...fourLevelComponents, sliced],
				}),
			};
		}
		return {
			componentsReplacement: getResult(
				threeLevelComponentsReplacement,
				id[2],
				{
					...threeLevelReplacement,
					[currentLang]: [...fourLevelComponentsReplacement, sliced],
				}
			),
		};
	};

	return getResult(componentCollection, id[0], {
		...firstLevel,
		components: getResult(twoLevelComponents, id[1], {
			...twoLevel,
			...getIsReplacement(),
		}),
	});
};

export const changePosition = (collection, id, direction) => {
	const change = (position, collection) => {
		if (direction === "up") {
			[collection[position], collection[position - 1]] = [
				collection[position - 1],
				collection[position],
			];
		}
		if (direction === "down") {
			[collection[position], collection[position + 1]] = [
				collection[position + 1],
				collection[position],
			];
		}
		return [...collection];
	};
	if (id?.length === 1) {
		let position = collection.findIndex((item) => item.id === id[0]);
		return change(position, collection);
	}
	if (id?.length === 3) {
		let firstLevel = collection.filter((item) => item.id === id[0])[0];
		let twoLevelComponents = firstLevel.components;
		let twoLevel = twoLevelComponents?.filter((item) => item.id === id[1])[0];
		let threeLevelComponents = twoLevel?.components;
		let position = threeLevelComponents.findIndex(
			(item) => item.id === id[2]
		);
		return getResult(collection, id[0], {
			...firstLevel,
			components: getResult(twoLevelComponents, id[1], {
				...twoLevel,
				components: change(position, threeLevelComponents),
			}),
		});
	}
};

export const chageImageFull = (collection, id, flag) => {
	let firstLevel = collection.filter((item) => item.id === id)[0];
	let itemForChanged = { ...firstLevel, fullImg: flag };
	let result = [];
	for (let item of collection) {
		if (item.id === id) {
			result = [...result, itemForChanged];
		} else {
			result = [...result, item];
		}
	}
	return result;
};
