import React, {
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";
import Block from "../Blocks/Block";
import BlockVideo from "../Blocks/BlockVideo";
import BorderActive from "../../workFields/BorderActive";
import { useBorderActive } from "../../../../hooks/useBorderActive";
import { setImageFull } from "../../../../redux/reducers/editorReducer";
import styled from "../../../../styles/editorPage/workField.module.scss";
import BlockSaintLaurent from "../Blocks/BlockSaintLaurent";

const Container = ({
	mainId,
	component,
	collection,
	isActivePage,
	device,
	minContainer,
}) => {
	const [refInView, inView] = useInView({ threshold: 0.1 });

	const {
		width,
		height,
		isHide,
		idActive,
		selectMainComponent,
		firstLoad,
		load,
		setChangeImage,
		ref,
		resize,
	} = useBorderActive(component, [component?.id]);

	const select = (e) => {
		resize();
		selectMainComponent(e);
	};

	const dispatch = useDispatch();

	const heightComponent = useMemo(() => {
		if (component.name === "block-image-w-half-h") {
			return styled.half_block;
		}
		if (component.name === "block-image-full-screen") {
			return styled.full_block;
		}
		return "";
	}, [component]);

	useEffect(() => {
		if (component?.name === "block-image") {
			if (component?.components.length === 2 && !component?.fullImg) {
				dispatch(setImageFull(component.id, true));
			}
			if (component?.components.length !== 2 && component?.fullImg) {
				dispatch(setImageFull(component.id, false));
			}
		}
	}, [collection]);

	// Логика для автоматического определения высоты видео
	const refContainer = useRef(null);
	const [heightVideo, setHeightVideo] = useState(0);
	useLayoutEffect(() => {
		if (heightVideo !== 1) setHeightVideo(refContainer.current.offsetHeight);
	}, [load, height]);

	useEffect(() => {
		setHeightVideo(1);
		const timer = setTimeout(() => {
			setHeightVideo(refContainer.current.offsetHeight);
		}, 500);
		return () => clearTimeout(timer);
	}, [device]);

	const getAnimateFlash = () => {
		if (component.animate === undefined || component.animate.showFlash) {
			return inView ? styled.mosaicCardShow : styled.mosaicCardHide;
		}
		return "";
	};

	return (
		<div
			className={`p-relative ${heightComponent}`}
			ref={ref}
			style={{ marginBottom: component?.styles?.marginBottom }}
		>
			{!isHide &&
				idActive.length === 1 &&
				idActive[0] === component?.id &&
				!isActivePage && (
					<BorderActive
						width={width}
						height={height}
						id={[mainId]}
						collection={collection}
						btnDown
						container
					/>
				)}
			<div
				ref={refInView}
				className={`${getAnimateFlash()} ${heightComponent}`}
			>
				<div
					className={`${styled.container} ${minContainer ? (device === "desktop" ? styled.minContainer : styled.minContainerSm) : ""} ${heightComponent}`}
					style={{
						gridGap: component?.styles.gridGap,
						gridTemplateColumns:
							component?.styles.gridTemplateColumns.join(" "),
					}}
					onMouseDown={select}
					onContextMenu={(e) => e.preventDefault()}
					ref={refContainer}
				>
					{component?.components.map((item, key) => {
						return (
							<React.Fragment key={key}>
								{item?.type === "video" &&
								component?.components.length > 1 ? (
									<BlockVideo
										firstLoad={firstLoad}
										component={item}
										mainId={mainId}
										load={load}
										isActivePage={isActivePage}
										device={device}
										parentName={component?.name}
										heightComponent={heightVideo}
									/>
								) : item?.type === "image" &&
								  item?.name === "block_outside" ? (
									<BlockSaintLaurent
										inView={inView}
										firstLoad={firstLoad}
										component={item}
										mainId={mainId}
										load={load}
										setChangeImage={setChangeImage}
										isActivePage={isActivePage}
										device={device}
										parentName={component?.name}
										heightComponent={heightComponent}
										collection={collection}
										componentsLength={component?.components.length}
									/>
								) : (
									<Block
										inView={inView}
										firstLoad={firstLoad}
										component={item}
										mainId={mainId}
										load={load}
										setChangeImage={setChangeImage}
										isActivePage={isActivePage}
										device={device}
										parentName={component?.name}
										heightComponent={heightComponent}
										collection={collection}
										componentsLength={component?.components.length}
									/>
								)}
							</React.Fragment>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Container;
