import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Controller, Autoplay } from "swiper";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import styled from "../../../../styles/editorPage/workField.module.scss";
import BorderActive from "../../workFields/BorderActive";
import BlockSwiper from "../Blocks/BlockSwiper";
import { useBorderActive } from "../../../../hooks/useBorderActive";
import Block from "../Blocks/Block";
import { getAutoplay } from "../../../../redux/selectors/editorSelectors";

// eslint-disable-next-line react-hooks/rules-of-hooks
SwiperCore.use([Controller, Autoplay]);

const SwiperContainerHalf = ({
	mainId,
	component,
	collection,
	isActivePage,
	device,
}) => {
	const [refInView, inView] = useInView({ threshold: 0.1 });

	const [firstSwiper, setFirstSwiper] = useState(null);
	const [isHiddenComponent, setHiddenComponent] = useState(false);
	const isAutoplayState = useSelector((state) => getAutoplay(state));
	const autoplay = localStorage.getItem("autoplay");
	const [isAutoplay, setAutoplay] = useState(!!autoplay);

	const {
		width,
		height,
		isHide,
		idActive,
		selectMainComponent,
		firstLoad,
		setChangeImage,
		firstLoadSwiper,
		load,
		ref,
		resize,
	} = useBorderActive(component, [component?.id]);

	const select = (e) => {
		resize();
		selectMainComponent(e);
	};

	const getAnimateFlash = () => {
		if (component.animate === undefined || component.animate.showFlash) {
			return inView ? styled.mosaicCardShow : styled.mosaicCardHide;
		}
		return "";
	};

	useEffect(() => {
		setHiddenComponent(true);
		const timer = setTimeout(() => {
			setHiddenComponent(false);
		}, 100);
		return () => clearTimeout(timer);
	}, [component?.sliderPosition]);

	useEffect(() => {
		setAutoplay(!isAutoplay);
		setHiddenComponent(true);
		const timer = setTimeout(() => {
			setHiddenComponent(false);
		}, 100);
		return () => clearTimeout(timer);
	}, [isAutoplayState]);

	return (
		<>
			{!isHiddenComponent && (
				<div className="p-relative" ref={ref}>
					{!isHide &&
						idActive.length === 1 &&
						idActive[0] === component.id &&
						!isActivePage && (
							<BorderActive
								width={width}
								height={height}
								id={[mainId]}
								collection={collection}
								btnDown
								container
							/>
						)}
					<div
						onMouseDown={select}
						onContextMenu={(e) => e.preventDefault()}
						ref={refInView}
						className={`${styled.swiperContainer} ${styled.halfType} ${getAnimateFlash()}`}
						style={{
							gridGap: component?.styles.gridGap,
							gridTemplateColumns:
								component?.styles.gridTemplateColumns.join(" "),
							direction:
								component?.sliderPosition === "right" ? "ltl" : "rtl",
						}}
					>
						<Block
							inView={inView}
							firstLoad={firstLoad}
							component={
								component?.components.filter(
									(item) => item.name === "block"
								)[0]
							}
							mainId={mainId}
							load={load}
							setChangeImage={setChangeImage}
							isActivePage={isActivePage}
							device={device}
							parentName={component?.name}
							heightComponent=""
							collection={collection}
							componentsLength={component?.components.length}
						/>
						<div
							style={{
								overflow: "visible",
								display: "contents",
							}}
						>
							<Swiper
								spaceBetween={0}
								slidesPerView={1}
								loop
								watchSlidesProgress
								autoplay={{
									delay: component?.autoplay?.delay || 3000,
									disableOnInteraction: false,
								}}
								speed={component?.autoplay?.speed || 700}
								nested
								onSwiper={setFirstSwiper}
								s
								controller={{ control: firstSwiper }}
								className="swiperHalf"
							>
								{component?.components
									.filter((item) => item.name === "swiper_phone")
									.map((item, id) => (
										<SwiperSlide
											key={id}
											className={!id ? "firstSlide" : ""}
										>
											<BlockSwiper
												index={id}
												component={item}
												mainId={mainId}
												load={load}
												phone
												isActivePage={isActivePage}
												collection={collection}
											/>
											{(component?.isProgressBar === undefined ||
												component?.isProgressBar) && (
												<div className="own-progress">
													<div
														className="bar-transition"
														style={{
															transition: `width ${component?.autoplay?.delay || 3000}ms ease 2s`,
														}}
													/>
													<div
														className={
															!id
																? "bar-transition-first"
																: "bar-transition"
														}
														style={{
															transition: `width ${component?.autoplay?.delay || 3000}ms ease 2s`,
														}}
													/>
												</div>
											)}
											{/*			<BlockSwiper
							firstLoad={firstLoadSwiper}
									component={item}
									mainId={mainId}
									load={load}
									phone={true}
									isActivePage={isActivePage}
									collection={collection}
								/> */}
											{/* {(component?.isProgressBar === undefined || component?.isProgressBar) &&
									<div className='own-progress'>
										<div
											className='bar-transition'
											style={{transition: `width ${component?.autoplay?.delay || 3000}ms ease 2s`}}
										/>
										<div
											className={!id ? 'bar-transition-first' : 'bar-transition'}
											style={{transition: `width ${component?.autoplay?.delay || 3000}ms ease 2s`}}
										/>
									</div>
								} */}
										</SwiperSlide>
									))}
							</Swiper>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SwiperContainerHalf;
