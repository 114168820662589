import React, { useEffect } from "react";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setMessages } from "../redux/reducers/errorsReducer";
import { setTemplates } from "../redux/actions/templatesAction";
import { getMessages } from "../redux/selectors/errorsSelectors";
import TemplatesList from "../components/TemplatesPage/TemplatesList";
import { setActiveComponent } from "../redux/reducers/editorReducer";
import {
	getActivePage,
	getTemplates,
} from "../redux/selectors/templatesSelectors";
import CreateTemplateButton from "../components/TemplatesPage/CreateTemplateButton";

const Templates = () => {
	const templates = useSelector((state) => getTemplates(state));
	const messages = useSelector((state) => getMessages(state));
	const activePage = useSelector((state) => getActivePage(state));

	const dispatch = useDispatch();

	const setMessagesRedux = (val) => dispatch(setMessages(val));
	const handleCloseError = () => setMessagesRedux([]);

	const showMessage = () => {
		message.info({
			content: messages[0],
			onClose: handleCloseError,
		});
	};

	useEffect(() => {
		dispatch(setActiveComponent([]));
	}, []);

	useEffect(() => {
		if (messages.length) {
			showMessage();
		}
	}, [messages]);

	useEffect(() => {
		dispatch(setTemplates(activePage));
	}, [activePage]);

	return (
		<>
			<CreateTemplateButton setMessages={setMessagesRedux} />
			<TemplatesList templates={templates} setMessages={setMessagesRedux} />
		</>
	);
};

export default Templates;
