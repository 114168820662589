import React from "react";
import styled from "../../../../styles/editorPage/workField.module.scss";
import BorderActive from "../../workFields/BorderActive";
import { useBorderActive } from "../../../../hooks/useBorderActive";
import { IsReplacementComponent } from "./lib/IsReplacementComponent";
import ImageAnimate from "./ImageAnimate";
import { useLogicBlocks } from "./lib/useLogicBlocks";

const BlockSwiper = (props) => {
	const {
		firstLoad,
		component,
		mainId,
		load,
		phone,
		isActivePage,
		collection,
		index,
	} = props;

	const { selectThisComponent, resize, width, height, isHide, ref, idActive } =
		useBorderActive(component, [mainId, component.id], load, null);

	const {
		toggleHover,
		setActiveOrLinkTo,
		setAnimate,
		loadImg,
		refBlock,
		sizePhoto,
		getStylesWithPaddings,
		isHovered,
		mouseOver,
	} = useLogicBlocks(
		load,
		collection,
		component,
		isHide,
		resize,
		selectThisComponent,
		firstLoad
	);

	return (
		<div
			className="p-relative"
			onMouseEnter={toggleHover}
			onMouseLeave={toggleHover}
			ref={refBlock}
		>
			{!isHide &&
				idActive.length === 2 &&
				idActive[1] === component?.id &&
				!isActivePage && (
					<BorderActive
						width={sizePhoto[0] || width}
						height={sizePhoto[1] || height}
						blockSwiper
						btnDown
						id={[mainId, component.id]}
					/>
				)}
			<div
				ref={ref}
				className={styled.itemContainer}
				onClick={setActiveOrLinkTo}
				onMouseOver={() => setAnimate(true)}
				onMouseOut={() => setAnimate(false)}
			>
				<div className={styled.imgSize} style={getStylesWithPaddings}>
					<div className={styled.swiperIndex}>{index + 1}</div>
					{sizePhoto[0] && sizePhoto[1] ? (
						<ImageAnimate
							src={component.image.url}
							width={sizePhoto[0]}
							height={sizePhoto[1]}
							isHovered={isHovered}
							hoverImage={component?.hover?.hoverBackground}
						/>
					) : (
						<img
							onLoad={loadImg}
							src={component.image.url}
							alt="Image"
							style={{ width: phone ? "100%" : "none" }}
						/>
					)}
				</div>
				<div
					className={`${styled.frontSpace} ${component?.hover?.hoverBackground ? styled.hoverBackground : ""}`}
					style={{
						justifyContent: component?.styles?.justifyContent,
					}}
				>
					{component?.colorDress.visible && (
						<div
							className={
								mouseOver
									? `${styled.cardColor} ${styled.cardColorsHover}`
									: `${styled.cardColor}`
							}
						>
							{component?.colorDress.colors.map((item, key) => {
								return (
									<div
										key={key}
										className={
											item === "#ffffff"
												? `${styled.color} ${styled.light}`
												: `${styled.color}`
										}
										style={{ background: item }}
									/>
								);
							})}
						</div>
					)}
					{component?.components?.map((item, key) => (
						<React.Fragment key={key}>
							<IsReplacementComponent
								item={item}
								mainId={mainId}
								component={component}
								mouseOver={mouseOver}
								isActivePage={isActivePage}
							/>
						</React.Fragment>
					))}
				</div>
			</div>
		</div>
	);
};

export default BlockSwiper;
