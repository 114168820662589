import Player from "react-player";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessages } from "../../../redux/reducers/errorsReducer";

export default function DashPlayerPrew({ src, poster, autoplay }) {
	// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();

	const handleLoaded = () => {
		setLoading(true);
		/*		dispatch(setMessages([])) */
	};
	const handleError = () => {
		setLoading(false);
		/*		dispatch(setMessages(['Некорректная ссылка на видео'])) */
	};
	/*
    const setMessagesRedux = (val) => {
      dispatch(setMessages(val))
    }
    */
	useEffect(() => {}, [loading]);

	return (
		<div className="p-relative d-block w-100" style={{ height: "100vh" }}>
			<div className={loading ? "poster-video hide" : "poster-video"}>
				<img
					src={poster}
					alt="здесь должно быть видео)"
					style={{
						width: "100%",
						height: "100vh",
						objectFit: "cover",
					}}
					className={
						loading ? "img-opacity-animate active" : "img-opacity-animate"
					}
				/>
			</div>
			<Player
				url={src}
				loop
				playsinline
				controls={false}
				muted
				playing={autoplay}
				autoPlay={autoplay}
				onReady={() => {
					handleLoaded();
				}}
				onError={() => {
					handleError();
				}}
				width="100%"
				height="100%"
				style={{
					objectFit: "cover",
					position: "relative",
					padding: "0",
					marginBottom: "-5px",
				}}
			/>
		</div>
	);
}
