import React from "react";
import styled from "../../styles/editorPage/exitViewButton.module.scss";
import { ReactComponent as CloseIco } from "../../assets/icons/0811-cross.svg";

const CloseViewButton = ({ isHide, hidePage }) => {
	return (
		<div className={`${styled.exitViewButton} ${!isHide ? "hide" : ""}`}>
			<CloseIco onClick={hidePage} />
		</div>
	);
};

export default CloseViewButton;
