import React from "react";
import { Checkbox, Form, Input } from "antd";
import styled from "../../../../styles/editorPage/rightBarEditor.module.scss";

const SettingTheme = ({ blockTheme, changeParams, isReplacement }) => {
	const changeActive = (checked) => {
		changeParams("blockTheme", checked, isReplacement);
	};

	return (
		<>
			{blockTheme !== undefined && (
				<>
					<div className={styled.textAndInput}>
						<div className={styled.label}>Тема:</div>
					</div>

					<div className={`${styled.blockThemeContainer}`}>
						<Checkbox
							checked={blockTheme}
							onChange={(e) => changeActive(e.target.checked)}
						>
							Темная тема
						</Checkbox>
					</div>
				</>
			)}
		</>
	);
};

export default SettingTheme;
