import React, { useEffect } from "react";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import HeaderEditor from "../components/Editor/HeaderEditor";
import RightBarEditor from "../components/Editor/RightBar/RigthBarEditor";
import WorkField from "../components/Editor/workFields/WorkField";
import {
	getDevice,
	getGuid,
	getIsActivePage,
	getIsHide,
	getNamePage,
} from "../redux/selectors/editorSelectors";
import { setActiveComponent, setHide } from "../redux/reducers/editorReducer";
import { setJSON } from "../redux/actions/EditorActions";
import { getMessages } from "../redux/selectors/errorsSelectors";
import { setMessages } from "../redux/reducers/errorsReducer";
import CloseViewButton from "../components/Editor/CloseViewButton";

const Editor = () => {
	const isHide = useSelector((state) => getIsHide(state));
	const device = useSelector((state) => getDevice(state));
	const namePage = useSelector((state) => getNamePage(state));
	const guid = useSelector((state) => getGuid(state));
	const isActivePage = useSelector((state) => getIsActivePage(state));
	const messages = useSelector((state) => getMessages(state));
	const { pageId } = useParams();

	const dispatch = useDispatch();

	const hidePage = () => {
		dispatch(setHide(false));
	};
	const getJSON = () => {
		dispatch(setJSON(pageId));
	};

	const setMessagesRedux = (val) => {
		dispatch(setMessages(val));
	};

	const handleCloseError = () => {
		setMessagesRedux([]);
	};

	const showMessage = () => {
		message.info({
			content: messages[0],
			onClose: handleCloseError,
		});
	};

	useEffect(() => {
		if (messages.length) {
			showMessage();
		}
	}, [messages]);

	useEffect(() => {
		getJSON();
	}, []);

	useEffect(() => {
		dispatch(setActiveComponent([]));
	}, []);

	return (
		<div>
			<HeaderEditor
				guid={guid}
				isHide={isHide}
				device={device}
				setMessages={setMessagesRedux}
				namePage={namePage}
				isActivePage={isActivePage}
			/>
			<WorkField
				isHide={isHide}
				device={device}
				isActivePage={isActivePage}
			/>
			<RightBarEditor isHide={isHide} isActivePage={isActivePage} />
			<CloseViewButton isHide={isHide} hidePage={hidePage} />
		</div>
	);
};

export default Editor;
