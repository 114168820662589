import React from "react";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import styled from "../../../../styles/editorPage/rightBarEditor.module.scss";

dayjs.extend(customParseFormat);

const SettingDatePiker = ({ datePiker, changeParams, type, isReplacement }) => {
	const setDate = (date, dateString) => {
		changeParams(type || "datePiker", { date: dateString }, isReplacement);
	};
	const setTime = (time, timeString) => {
		changeParams(type || "datePiker", { time: timeString }, isReplacement);
	};

	const dateFormat = "DD MMM YYYY";
	const timeFormat = "HH:mm:ss";

	function disabledDate(current) {
		return current && current.valueOf() <= Date.now() - 24 * 3600 * 1000;
	}

	return (
		<div>
			{datePiker?.date ? (
				<div>
					<div className={styled.textAndInput}>
						<div className={styled.label}>Дата:</div>
						<DatePicker
							onChange={setDate}
							defaultValue={moment(datePiker.date, dateFormat)}
							format={dateFormat}
							inputReadOnly
							allowClear={false}
							disabledDate={disabledDate}
						/>
					</div>
				</div>
			) : (
				<></>
			)}
			{datePiker?.time ? (
				<div>
					<div className={styled.textAndInput}>
						<div className={styled.label}>Время:</div>
						<TimePicker
							onChange={setTime}
							defaultValue={dayjs(datePiker.time, timeFormat)}
							inputReadOnly
							allowClear={false}
						/>
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

export default SettingDatePiker;
