import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Radio } from "antd";
import {
	setActiveComponent,
	setDevice,
} from "../../../../redux/reducers/editorReducer";

const SettingDevice = ({ device }) => {
	const dispatch = useDispatch();

	const changeDevice = (value) => {
		dispatch(setActiveComponent([]));
		dispatch(setDevice(value));
	};

	return (
		<Radio.Group
			defaultValue={device}
			onChange={(e) => changeDevice(e.target.value)}
			key="3"
		>
			<Radio.Button value="desktop">
				<span className="icon-laptop fs-17" />
			</Radio.Button>
			{/*		<Radio.Button value="tablet">
				<span className="icon-tablet2 fs-17"/>
			</Radio.Button> */}
			<Radio.Button value="phone">
				<span className="icon-phone fs-17" />
			</Radio.Button>
		</Radio.Group>
	);
};

export default SettingDevice;
