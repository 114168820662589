import { useEffect, useState } from "react";

export const useDynamicGradientBg = (
	imageRef,
	blockRef,
	startColor,
	endColor
) => {
	const [overlapPercentage, setOverlapPercentage] = useState(0);

	useEffect(() => {
		const calculateOverlapPercentage = () => {
			const imageElement = imageRef.current;
			const blockElement = blockRef.current;

			if (imageElement && blockElement) {
				const imageRect = imageElement.getBoundingClientRect();
				const blockRect = blockElement.getBoundingClientRect();

				const overlapHeight = Math.max(
					0,
					Math.min(imageRect.bottom, blockRect.bottom) -
						Math.max(imageRect.top, blockRect.top)
				);
				const overlapPercentage = (overlapHeight / blockRect.height) * 100;

				setOverlapPercentage(overlapPercentage);
			}
		};

		window.addEventListener("scroll", calculateOverlapPercentage);
		calculateOverlapPercentage();

		return () => {
			window.removeEventListener("scroll", calculateOverlapPercentage);
		};
	}, []);

	return `linear-gradient(0deg, ${startColor} ${100 - Math.floor(overlapPercentage)}%, ${endColor} ${100 - Math.floor(overlapPercentage)}%)`;
};
