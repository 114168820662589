import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "../../../../../styles/editorPage/workField.module.scss";
import BorderActive from "../../../workFields/BorderActive";
import { getLang } from "../../../../../redux/selectors/editorSelectors";
import { useBorderActive } from "../../../../../hooks/useBorderActive";
import Sliced from "./Sliced";

const TextSliced = ({
	mainId,
	blockId,
	component,
	mouseOver,
	hover,
	isActivePage,
	collection,
}) => {
	const lang = useSelector((state) => getLang(state));

	const { width, height, isHide, ref, resize, idActive, selectMainComponent } =
		useBorderActive(component, [mainId, blockId, component.id]);

	const select = (e) => {
		resize();
		selectMainComponent(e);
	};
	const classHover = useMemo(() => {
		if (!hover) {
			return "";
		}
		if (mouseOver) {
			return `${styled.textHover} ${styled.textHoverActive}`;
		}
		return styled.textHover;

		return "";
	}, [hover, mouseOver]);

	return (
		<div
			className={styled.frontSpaceWrapper}
			style={{
				alignSelf: component?.styles.alignSelf,
			}}
		>
			<div
				className="p-relative"
				style={{
					margin: component.styles.margin.join(" "),
				}}
			>
				{!isHide &&
					idActive.length === 3 &&
					component.id === idActive[2] &&
					!isActivePage && (
						<BorderActive
							width={width}
							height={height}
							id={[mainId, blockId, component.id]}
							large
							collection={collection}
							container
						/>
					)}
				<div
					className={classHover}
					ref={ref}
					style={{
						fontWeight: component.styles.fontWeight,
						fontSize: component.styles.fontSize,
						fontFamily: component.styles.fontFamily,
						fontStyle: component.styles.fontStyle || "normal",
						color: component.styles.color,
						display: "flex",
						alignItems: "center",
					}}
					onMouseDown={select}
				>
					{component[lang]?.map((item) => (
						<div
							key={item.id}
							style={{
								whiteSpace: "pre",
							}}
						>
							<Sliced
								mainId={mainId}
								blockId={blockId}
								elemId={component.id}
								component={item}
								componentSP={component.style}
								isActivePage={isActivePage}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default TextSliced;
