import Player from "react-player";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setMessages } from "../../../redux/reducers/errorsReducer";
import BorderActive from "./BorderActive";
import styled from "../../../styles/editorPage/workField.module.scss";
import { IsReplacementComponent } from "../components/Blocks/lib/IsReplacementComponent";
import { useBorderActive } from "../../../hooks/useBorderActive";

export default function DashPlayerBlock({
	src,
	poster,
	autoplay,
	component,
	mainId,
	isActivePage,
	firstLoad,
	heightComponent,
}) {
	// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

	const [mouseOver, setMouseOver] = useState(false);
	const [loading, setLoading] = useState(false);

	let navigate = useNavigate();

	const dispatch = useDispatch();

	const setAnimate = (flag) => {
		if (component?.hover?.hoverText) {
			setMouseOver(flag);
		}
	};

	const handleLoaded = () => {
		setLoading(true);
	};
	const handleError = () => {
		setLoading(false);
	};

	useEffect(() => {}, [loading]);

	return (
		<div className="p-relative d-block w-100" style={{ height: "100%" }}>
			<div
				className="rtrtrtrtrtrtrt"
				style={{
					position: "absolute",
					height: `${heightComponent}px`,
					width: "100%",
					top: 0,
					left: 0,
					zIndex: 1,
				}}
			>
				<div
					className={styled.itemContainer}
					onMouseOver={() => setAnimate(true)}
					onMouseOut={() => setAnimate(false)}
					style={{ height: `${heightComponent}px` }}
				>
					<div
						className={`${styled.frontSpace} ${component?.hover?.hoverBackground ? styled.hoverBackground : ""}`}
						style={{
							justifyContent: component?.styles?.justifyContent,
						}}
					>
						{component?.components?.map((item, key) => (
							<React.Fragment key={key}>
								<IsReplacementComponent
									item={item}
									mainId={mainId}
									component={component}
									mouseOver={mouseOver}
									isActivePage={isActivePage}
								/>
							</React.Fragment>
						))}
					</div>
				</div>
			</div>
			<div className={loading ? "poster-video hide" : "poster-video"}>
				<img
					src={poster}
					alt="здесь должно быть видео)"
					onLoad={firstLoad}
					style={{
						width: "100%",
						objectFit: "cover",
					}}
					className={
						loading ? "img-opacity-animate active" : "img-opacity-animate"
					}
				/>
			</div>
			<Player
				url={src}
				loop
				playsinline
				controls={false}
				muted
				playing={autoplay}
				autoPlay={autoplay}
				onReady={() => {
					handleLoaded();
				}}
				onError={() => {
					handleError();
				}}
				width="100%"
				height="100%"
				style={{
					objectFit: "cover",
					position: "relative",
					padding: "0",
					marginBottom: "-5px",
				}}
			/>
		</div>
	);
}
