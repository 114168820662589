import React, { useMemo, useRef } from "react";
import ImageAnimate from "./ImageAnimate";
import { useLogicBlocks } from "./lib/useLogicBlocks";
import BorderActive from "../../workFields/BorderActive";
import { useBorderActive } from "../../../../hooks/useBorderActive";
import { IsReplacementComponent } from "./lib/IsReplacementComponent";
import styled from "../../../../styles/editorPage/workField.module.scss";

/**
 * @author Zholaman Zhumanov
 * @created 25.07.2024
 * @description
 * @last-updated
 * @update-description
 * @todo
 * @fixme
 * @param props
 * @constructor
 */
function BlockSaintLaurent(props) {
	const {
		firstLoad,
		component,
		mainId,
		load,
		isActivePage,
		device,
		parentName,
		heightComponent,
		inView,
		collection,
		componentsLength,
	} = props;

	const { selectThisComponent, resize, width, height, isHide, ref, idActive } =
		useBorderActive(component, [mainId, component.id], load);

	const {
		toggleHover,
		setActiveOrLinkTo,
		setAnimate,
		loadImg,
		refBlock,
		sizePhoto,
		isHovered,
		mouseOver,
		isPadding,
	} = useLogicBlocks(
		load,
		collection,
		component,
		isHide,
		resize,
		selectThisComponent,
		firstLoad,
		device,
		componentsLength
	);

	const refImg = useRef(null);

	const getHeightItemContainer = useMemo(() => {
		if (
			parentName === "block-image-full-screen" ||
			parentName === "block-image-w-half-h"
		) {
			return "100%";
		}
		if (isPadding && sizePhoto[1]) {
			return `${sizePhoto[1]}px`;
		}
		return "auto";
	}, [parentName, sizePhoto, isPadding]);

	return (
		<div
			className={`p-relative ${heightComponent}`}
			onMouseEnter={toggleHover}
			onMouseLeave={toggleHover}
			ref={refBlock}
		>
			{!isHide &&
				idActive.length === 2 &&
				idActive[1] === component?.id &&
				!isActivePage && (
					<BorderActive
						width={width}
						height={height}
						btnDown
						id={[mainId, component.id]}
					/>
				)}
			<div
				className={styled.itemContainer}
				ref={ref}
				onClick={setActiveOrLinkTo}
				onMouseOver={() => setAnimate(true)}
				onMouseOut={() => setAnimate(false)}
				style={{ height: getHeightItemContainer }}
			>
				<div className={styled.mosaic_saint_laurent_block}>
					<div
						className={
							device === "phone"
								? styled.imgSizeOutsideSm
								: styled.imgSizeOutside
						}
					>
						<figure>
							{sizePhoto[0] && sizePhoto[1] ? (
								<ImageAnimate
									src={component.image.url}
									width={sizePhoto[0]}
									height={sizePhoto[1]}
									isHovered={isHovered}
									hoverImage={component?.hover?.hoverBackground}
									isAppearance={inView}
									appearanceImage={component?.animate?.appearanceImage}
									appearanceImageIncrease={
										component?.animate?.appearanceImageIncrease
									}
									ref={refImg}
								/>
							) : (
								<img
									onLoad={loadImg}
									src={component.image.url}
									alt="Image"
								/>
							)}
						</figure>
					</div>
				</div>
				{!!sizePhoto[1] && (
					<div
						className={`${styled.frontSpace} ${styled.outsideSpace}`}
						style={{
							justifyContent: component?.styles?.justifyContent,
						}}
					>
						{component?.components?.map((item, key) => (
							<React.Fragment key={key}>
								<IsReplacementComponent
									item={item}
									mainId={mainId}
									component={component}
									mouseOver={mouseOver}
									isActivePage={isActivePage}
								/>
							</React.Fragment>
						))}
					</div>
				)}
			</div>
		</div>
	);
}

export default BlockSaintLaurent;
