import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import phoneBG from "../../../assets/phoneBG.png";
import tabletBG from "../../../assets/tabletBG.png";
import Container from "../components/Containers/Container";
import VideoContainer from "../components/Containers/VideoContainer";
import SwiperCategory from "../components/Containers/SwiperCategory";
import styled from "../../../styles/editorPage/workField.module.scss";
import SwiperContainer from "../components/Containers/SwiperContainer";
import VideoContainerPrew from "../components/Containers/VideoContainerPrew";
import SwiperContainerHalf from "../components/Containers/SwiperContainerHalf";
import SwiperContainerPhone from "../components/Containers/SwiperContainerPhone";
import {
	getComponentCollection,
	getComponentCollectionMobile,
} from "../../../redux/selectors/editorSelectors";
import SwiperNewCategory from "../components/Containers/SwiperNewCategory";

/**
 * @description Компонент для вывода компонентов для рабочее пространство
 * @todo refactoring
 * @param isHide
 * @param device
 * @param isActivePage
 * @returns {JSX.Element}
 * @constructor
 */
const WorkField = ({ isHide, device, isActivePage }) => {
	const componentCollection = useSelector((state) =>
		getComponentCollection(state)
	);
	const componentCollectionMobile = useSelector((state) =>
		getComponentCollectionMobile(state)
	);

	/**
	 * @description Получение ширины экрана для устройств
	 * @type {*|string}
	 */
	const width = useMemo(() => {
		if (device === "tablet") {
			return styled.tablet;
		}
		if (device === "phone") {
			return styled.phone;
		}
		return "";
	}, [device]);

	/**
	 * @description Метод для получения данных шаблонов мобильной или десктопный версий
	 */
	const collection = useMemo(() => {
		return device === "desktop"
			? componentCollection
			: componentCollectionMobile;
	}, [device, componentCollection, componentCollectionMobile]);

	return (
		<div className={`${styled.workFieldContainer} ${isHide ? "p_0" : ""}`}>
			{isHide && device !== "desktop" ? (
				<div
					style={{
						position: "absolute",
						width: "100%",
						justifyContent: "center",
						display: "flex",
						zIndex: 1000000,
						pointerEvents: "none",
					}}
				>
					<img
						src={device === "tablet" ? tabletBG : phoneBG}
						alt=""
						style={{
							width: device === "tablet" ? "932px" : "492px",
							margin: "auto",
							pointerEvents: "none",
							height: device === "tablet" ? "auto" : "930px",
						}}
					/>
				</div>
			) : null}

			<div
				className={`${isHide && device === "tablet" ? styled.tabletBG : ""} ${isHide && device === "phone" ? styled.phoneBG : ""} ${styled.workFieldArea}`}
			>
				<div className={`${styled.workField} ${width}`}>
					{collection.map((item, key) => (
						<React.Fragment key={key}>
							{(item.name === "block-image" ||
								item.name === "stories-single" ||
								item.name === "block-image-w-half-h" ||
								item.name === "block-image-full-screen" ||
								item?.name === "block-image-saint") && (
								<Container
									device={device}
									mainId={item.id}
									component={item}
									collection={collection}
									isActivePage={isActivePage}
									minContainer={item?.name === "block-image-saint"}
								/>
							)}

							{item.name === "block-slider" && (
								<SwiperContainer
									mainId={item.id}
									component={item}
									collection={collection}
									isActivePage={isActivePage}
								/>
							)}

							{item?.name === "block-slider-full" ? (
								<SwiperContainerPhone
									mainId={item.id}
									component={item}
									collection={collection}
									isActivePage={isActivePage}
								/>
							) : item?.name === "saint-laurent-slider-full" ||
							  item?.name === "saint-slider-full" ? (
								<SwiperContainerPhone
									device={device}
									mainId={item.id}
									component={item}
									collection={collection}
									isActivePage={isActivePage}
									minContainer={
										item?.name === "saint-laurent-slider-full" ||
										item?.name === "saint-slider-full"
									}
									outsideComponent={
										item?.name === "saint-laurent-slider-full" ||
										item?.name === "saint-slider-full"
									}
								/>
							) : null}

							{item?.name === "stories-multi" && (
								<SwiperContainerPhone
									mainId={item.id}
									component={item}
									collection={collection}
									isActivePage={isActivePage}
								/>
							)}

							{item.name === "block-slider-image" && (
								<SwiperContainerHalf
									mainId={item.id}
									component={item}
									collection={collection}
									isActivePage={isActivePage}
									device={device}
								/>
							)}

							{item.name === "block-video" && item.components ? (
								<VideoContainer
									mainId={item.id}
									component={item}
									collection={collection}
									isActivePage={isActivePage}
								/>
							) : item.name === "block-video" && !item.components ? (
								<VideoContainerPrew
									mainId={item.id}
									component={item}
									collection={collection}
									isActivePage={isActivePage}
								/>
							) : null}

							{item.name === "block-slider-category" && (
								<SwiperCategory
									mainId={item.id}
									component={item}
									collection={collection}
									isActivePage={isActivePage}
								/>
							)}

							{item.name === "block-slider-category-v2" && (
								<SwiperNewCategory
									mainId={item.id}
									component={item}
									collection={collection}
									isActivePage={isActivePage}
								/>
							)}
						</React.Fragment>
					))}
				</div>
			</div>
		</div>
	);
};

export default WorkField;
