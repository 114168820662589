import React from "react";
import { Input } from "antd";
import styled from "../../../../styles/editorPage/rightBarEditor.module.scss";

const SettingSlidePerView = ({ slidePerView, changeParams, isReplacement }) => {
	return (
		<>
			{slidePerView !== undefined ? (
				<div>
					<div className={styled.textAndInput}>
						<div className={styled.label}>Отображение:</div>
						<Input
							style={{ width: "75px" }}
							size="small"
							defaultValue={slidePerView}
							onChange={(e) =>
								changeParams(
									"slidePerView",
									e.target.value,
									isReplacement
								)
							}
						/>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default SettingSlidePerView;
