const $url = "https://app3.lichishop.com/";
export { $url };

const userData =
	process.env.NODE_ENV !== "development"
		? window?.BXAPP?.USER_DATA
		: "S3sRASJrGAt0e3ZzTFF2CWEbbw15HwcTIzkOChJjDDAfPSJtEgQAbnh7fnABC3Z2CW95aEIHdQ5sDHMdBC06YVVNIScFbl5nECxzcBIx";

const sendFormFM = (method, path, name, file, object, type) => {
	let myHeaders = new Headers();
	myHeaders.append(
		"User-Agent",
		"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
	);

	let formdata = new FormData();
	formdata.append("app", "site_admin");
	formdata.append("do", `constructor_fm${method}`);
	formdata.append("USER_DATA", userData);

	if (file) {
		formdata.append("file", file);
	}
	if (path) {
		formdata.append("path", path);
	}
	if (name) {
		formdata.append("name", name);
	}
	if (object) {
		formdata.append("object", object);
	}
	if (type) {
		formdata.append("type", type);
	}

	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: formdata,
		redirect: "follow",
	};

	return fetch("https://app3.lichishop.com/api/", requestOptions)
		.then((response) => response.json())
		.then((result) => result)
		.catch((error) => console.error("error", error));
};

const sendFormHP = (
	method,
	name,
	page,
	id,
	schema,
	schemaMobile,
	use,
	meta
) => {
	let myHeaders = new Headers();
	myHeaders.append(
		"User-Agent",
		"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
	);

	let formdata = new FormData();
	formdata.append("app", "site_admin");
	formdata.append("do", `constructor_hp${method}`);
	formdata.append("USER_DATA", userData);

	if (name) {
		formdata.append("name", name);
	}
	if (page) {
		formdata.append("page", page);
	}
	if (id) {
		formdata.append("id", id);
	}
	if (schema) {
		formdata.append("schema", JSON.stringify(schema));
	}
	if (schemaMobile) {
		formdata.append("schema_mobile", JSON.stringify(schemaMobile));
	}
	if (use) {
		formdata.append("use", JSON.stringify(use));
	}
	if (meta) {
		formdata.append("meta", JSON.stringify(meta));
	}

	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: formdata,
		redirect: "follow",
	};

	return fetch("https://app3.lichishop.com/api/", requestOptions)
		.then((response) => response.json())
		.then((result) => result)
		.catch((error) => console.error("error", error));
};
const sendFormGet = (method) => {
	let myHeaders = new Headers();
	myHeaders.append(
		"User-Agent",
		"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
	);

	let formdata = new FormData();
	formdata.append("app", "site_admin");
	formdata.append("do", `constructor_get${method}`);
	formdata.append("USER_DATA", userData);

	let requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: formdata,
		redirect: "follow",
	};

	return fetch("https://app3.lichishop.com/api/", requestOptions)
		.then((response) => response.json())
		.then((result) => result)
		.catch((error) => console.error("error", error));
};

export const imageAPI = {
	getFolders() {
		return sendFormFM("Tree");
	},
	getFiles(path) {
		return sendFormFM("Get", path);
	},
	uploadFile(path, file) {
		return sendFormFM("UploadFile", path, null, file);
	},
	createFolder(path, name) {
		return sendFormFM("CreateDir", path, name);
	},
	deleteFolder(object, type) {
		return sendFormFM("Remove", null, null, null, object, type);
	},
};

export const editorAPI = {
	getLanguages() {
		return sendFormGet("LanguageList");
	},
	getShops() {
		return sendFormHP("GetShops");
	},
	getList(page) {
		return sendFormHP("List", null, page);
	},
	saveSchema(id, schema, schemaMobile, meta) {
		return sendFormHP(
			"SaveSchema",
			null,
			null,
			id,
			schema,
			schemaMobile,
			null,
			meta
		);
	},
	getById(id) {
		return sendFormHP("GetById", null, null, id);
	},
	deletePage(id) {
		return sendFormHP("Delete", null, null, id);
	},
	copyPage(id) {
		return sendFormHP("Copy", null, null, id);
	},
	create(name) {
		return sendFormHP("Create", name);
	},
	activate(id, use) {
		return sendFormHP("SetActive", null, null, id, null, null, use);
	},
	changeName(meta, id) {
		return sendFormHP("UpdateMeta", meta, null, id);
	},
};
