import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { getTheme } from "../../../../../redux/selectors/editorSelectors";

/**
 * @todo Удалить проверку на paddings 10.03.2024
 * @param load
 * @param collection
 * @param component
 * @param isHide
 * @param resize
 * @param selectThisComponent
 * @param firstLoad
 * @param device
 * @param componentsLength
 * @returns {{isHovered: boolean, sizePhoto: number[], refBlock: React.MutableRefObject<null>, setAnimate: setAnimate, isPadding: boolean, toggleHover: toggleHover, setActiveOrLinkTo: setActiveOrLinkTo, mouseOver: boolean, loadImg: loadImg, getStylesWithPaddings: ({padding: *, background: *|string, width: number, height: number}|{width: number, height: number}|{})}}
 */
export const useLogicBlocks = (
	load,
	collection,
	component,
	isHide,
	resize,
	selectThisComponent,
	firstLoad,
	device,
	componentsLength
) => {
	const [mouseOver, setMouseOver] = useState(false);
	const [sizePhoto, setSizePhoto] = useState([0, 0]);
	const [isHovered, setIsHovered] = useState(false);

	const theme = useSelector(getTheme);

	useEffect(() => {
		setTimeout(() => {
			setSizePhoto([0, 0]);
		}, 500);
	}, [isHide, device, component.image, component.video, componentsLength]);

	const toggleHover = () => {
		if (component?.hover?.hoverBackground) {
			setIsHovered(!isHovered);
		}
	};

	let navigate = useNavigate();

	const refBlock = useRef(null);

	const setAnimate = (flag) => {
		if (component?.hover?.hoverText || component?.colorDress?.visible) {
			setMouseOver(flag);
		}
	};

	const setActiveOrLinkTo = (event) => {
		if (!isHide) {
			resize();
			selectThisComponent(event);
		} else if (component?.link?.isActive) {
			if (component?.link?.out) {
				window.open(component.link.path);
			} else {
				let arr = component.link.path.split(".com");
				navigate(arr[1].slice(7));
			}
		}
	};

	const isSetPadding = (paddingArray) => {
		let res = false;
		paddingArray.forEach((item) => {
			item !== "0px" && (res = true);
		});
		return res;
	};

	const isPadding = useMemo(() => {
		if (
			component?.styles?.padding &&
			isSetPadding(component.styles.padding)
		) {
			return true;
		}
		if (
			!component?.styles?.padding ||
			!isSetPadding(component.styles.padding)
		) {
			return false;
		}
	}, [component?.styles?.padding]);

	const getStylesWithPaddings = useMemo(() => {
		if (isPadding && sizePhoto[1]) {
			return {
				padding: component.styles.padding.join(" "),
				background:
					theme === "light"
						? component.styles.background
						: component.styles.backgroundDark,
				width: sizePhoto[0],
				height: sizePhoto[1],
			};
		}
		if (!isPadding && sizePhoto[1]) {
			return {
				width: sizePhoto[0],
				height: sizePhoto[1],
			};
		}
		return {};
	}, [isPadding, sizePhoto, component?.styles, theme]);

	const loadImg = () => {
		firstLoad();
		if (!sizePhoto[1]) {
			const { width, height } = refBlock.current.getBoundingClientRect();
			setSizePhoto([width, height]);
		}
	};

	return {
		toggleHover,
		refBlock,
		sizePhoto,
		setActiveOrLinkTo,
		setAnimate,
		getStylesWithPaddings,
		isHovered,
		loadImg,
		mouseOver,
		isPadding,
	};
};
