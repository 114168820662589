import React from "react";
import { Checkbox, Form, Input, Select } from "antd";
import ReactDOM from "react-dom/client";
import { Option } from "antd/es/mentions";
import styled from "../../../../styles/editorPage/rightBarEditor.module.scss";

const SettingTypeHalfSlider = ({
	sliderPosition,
	changeParams,
	isReplacement,
}) => {
	const changePosition = (value) => {
		changeParams("sliderPosition", value, isReplacement);
	};

	return (
		<>
			{sliderPosition ? (
				<div>
					<div className={styled.textAndInput}>
						<div className={styled.label}>Слайдер:</div>
						<Select
							style={{
								width: 350,
							}}
							defaultValue={sliderPosition}
							onChange={changePosition}
							getPopupContainer={(trigger) => trigger.parentNode}
						>
							<Option value="right">Справа</Option>
							<Option value="left">Слева</Option>
						</Select>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default SettingTypeHalfSlider;
