import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import SwiperCore, { Controller } from "swiper";
import { useMediaQuery } from "react-responsive";
import { useInView } from "react-intersection-observer";
import React, { useEffect, useRef, useState } from "react";
import BorderActive from "../../workFields/BorderActive";
import { useBorderActive } from "../../../../hooks/useBorderActive";
import sliderLastBlock from "../../../../assets/sliderLastBlock.jpg";
import { getDevice } from "../../../../redux/selectors/editorSelectors";
import styled from "../../../../styles/editorPage/workField.module.scss";

// eslint-disable-next-line react-hooks/rules-of-hooks
SwiperCore.use([Controller]);

/**
 * @author Alexey Gomozev
 * @todo refactoring
 * @param refImage
 * @param item
 * @param phone
 * @param device
 * @returns {JSX.Element}
 * @constructor
 */
const WrapperBlock = ({ refImage, item, phone, device }) => {
	const [mouseOver, setMouseOver] = useState(false);

	const setAnimate = (flag) => {
		if (device === "desktop") {
			setMouseOver(flag);
		}
	};

	return (
		<div className="p-relative">
			<div
				className={styled.itemContainer}
				onMouseOver={() => setAnimate(true)}
				onMouseOut={() => setAnimate(false)}
			>
				<div className={styled.imgSize}>
					<img
						ref={refImage}
						src={item.photos[0].thumbs["768_1024"]}
						alt=""
						style={{ width: phone ? "100%" : "none" }}
					/>
				</div>
				<div
					className={`${styled.frontSpace} `}
					style={{
						backgroundImage: `url(${item.photos[0].thumbs["768_1024"]})`,
						justifyContent: "end",
					}}
				>
					<div
						className={
							mouseOver
								? `${styled.cardColor} ${styled.cardColorsHover}`
								: `${styled.cardColor}`
						}
					>
						<div
							className={
								item.colors.current.value == "FFFFFF"
									? `${styled.color} ${styled.light}`
									: `${styled.color}`
							}
							style={{
								background: `#${item.colors.current.value}`,
							}}
						/>
						{item.colors.other.map((item, key) => {
							return (
								<div
									key={key}
									className={
										item.value == "FFFFFF"
											? `${styled.color} ${styled.light}`
											: `${styled.color}`
									}
									style={{ background: `#${item.value}` }}
								/>
							);
						})}
					</div>
					{device === "desktop" ? (
						<div
							className={styled.frontSpaceWrapper}
							style={{
								alignSelf: "center",
								width: "80%",
							}}
						>
							<div
								style={{
									marginBottom: "30px",
									color: "#FFFFFF",
									overflow: "hidden",
									textOverflow: "ellipsis",
									textWrap: "nowrap",
									whiteSpace: "nowrap",
								}}
							>
								{item.name.toUpperCase()}
							</div>
							<div
								style={{
									marginBottom: "30px",
									color: "#FFFFFF",
								}}
							>
								{item.format_price[1]}
							</div>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
};

/**
 * @author Alexey Gomozev
 * @todo refactoring
 * @param mainId
 * @param component
 * @param collection
 * @param isActivePage
 * @returns {JSX.Element}
 * @constructor
 */
const SwiperCategory = ({ mainId, component, collection, isActivePage }) => {
	const [params, setParams] = useState([]);
	const [firstSwiper, setFirstSwiper] = useState(null);
	const [refInView, inView] = useInView({ threshold: 0.1 });

	const initedSwiper = (swiper) => {
		setFirstSwiper(swiper);
	};

	const refImage = useRef();
	const device = useSelector((state) => getDevice(state));
	const k5 = useMediaQuery({ minWidth: 3840 });
	const k4 = useMediaQuery({ minWidth: 2160, maxWidth: 3839.98 });
	const tableBig = useMediaQuery({ minWidth: 768, maxWidth: 1199.98 });
	const phone = useMediaQuery({ maxWidth: 575.98 });
	const slidePerView = k4
		? 7.3
		: k5
			? 8.3
			: tableBig
				? 4.3
				: device === "tablet"
					? 3.3
					: device === "phone"
						? 2.3
						: 4.4;

	const { width, height, isHide, idActive, selectMainComponent, ref, resize } =
		useBorderActive(component, [component?.id]);

	const select = (e) => {
		resize();
		selectMainComponent(e);
	};

	const getAnimateFlash = () => {
		if (component.animate === undefined || component.animate.showFlash) {
			return inView ? styled.mosaicCardShow : styled.mosaicCardHide;
		}
		return "";
	};

	const getParams = async () => {
		let myHeaders = new Headers();
		myHeaders.append(
			"User-Agent",
			"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
		);

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		const res = await fetch(
			`https://api.lichi.com/category/get_category_product_list?category=${component.category}&limit=${component.count}&lang=${component.lang}&shop=${component.shop}`,
			requestOptions
		)
			.then((response) => response.json())
			.then((result) => result)
			.catch((error) => console.error("error", error));
		setParams(res.api_data.aProduct);
	};

	useEffect(() => {
		getParams();
	}, [component]);

	return (
		<div className="p-relative" ref={ref}>
			{!isHide &&
				idActive.length === 1 &&
				idActive[0] === component.id &&
				!isActivePage && (
					<BorderActive
						width={width}
						height={height}
						id={[mainId]}
						collection={collection}
						btnDown
						container
					/>
				)}
			<div
				onMouseDown={select}
				onContextMenu={(e) => e.preventDefault()}
				ref={refInView}
				className={`${styled.swiperContainer} ${getAnimateFlash()}`}
			>
				<Swiper
					className="mySwiper"
					onSwiper={initedSwiper}
					controller={{ control: firstSwiper }}
					slidesPerView={slidePerView}
					spaceBetween={+component?.styles?.spaceBetween || 1}
					slidesPerGroup={phone ? 1 : 4}
					speed={phone ? 300 : 1300}
					style={{
						marginBottom: component?.styles?.marginBottom,
					}}
				>
					{params.map((item, key) => (
						<SwiperSlide key={key}>
							<WrapperBlock
								refImage={refImage}
								item={item}
								phone={phone}
								device={device}
							/>
						</SwiperSlide>
					))}
					<SwiperSlide>
						<div className="p-relative">
							<div className={styled.itemContainer}>
								<div className={styled.imgSize}>
									<img
										ref={refImage}
										src={sliderLastBlock}
										alt=""
										style={{
											width: phone ? "100%" : "none",
										}}
									/>
								</div>
							</div>
						</div>
					</SwiperSlide>
				</Swiper>
			</div>
		</div>
	);
};

export default SwiperCategory;
