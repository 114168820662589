import React from "react";
import { Checkbox, Input } from "antd";
import styled from "../../../../styles/editorPage/rightBarEditor.module.scss";

const SettingVideo = ({
	video,
	isPlay,
	dash_src,
	changeParams,
	isReplacement,
	type,
	name,
}) => {
	const changeVideo = (val) => {
		if (!val) {
			val =
				"https://player.vimeo.com/progressive_redirect/playback/732365807/rendition/1080p/file.mp4?loc=external&signature=7cfecca5db8086cb6e8f13fc97dd7b504f0ac73ba819a24293c3233a21df8a25";
		}
		changeParams("video", val, isReplacement);
	};

	const changeIsPlay = (val) => {
		changeParams("isPlay", val, isReplacement);
	};

	return (
		<>
			{!(name === "block" && type === "image") && (
				<>
					{video ? (
						<div style={{ marginTop: "15px" }}>
							<h3>Видео</h3>
							<div>
								<div style={{ width: "100%" }}>
									<div className={styled.linkContainer}>
										<Input
											placeholder="path"
											value={video}
											onChange={(e) => changeVideo(e.target.value)}
										/>
									</div>
								</div>
							</div>
						</div>
					) : null}
					{isPlay !== undefined ? (
						<div className={"mx-15 mt-5"}>
							<div className={styled.checkbox}>
								<Checkbox
									checked={isPlay}
									onChange={(e) => changeIsPlay(e.target.checked)}
								>
									Воспрпоизвести
								</Checkbox>
							</div>
						</div>
					) : null}
					{dash_src !== undefined ? (
						<div style={{ marginTop: "15px" }}>
							<h3>Dash видео</h3>
							<div>
								<div style={{ width: "100%" }}>
									<div className={styled.linkContainer}>
										<Input
											placeholder="path"
											value={dash_src}
											onChange={(e) =>
												changeParams(
													"dash_src",
													e.target.value,
													isReplacement
												)
											}
										/>
									</div>
								</div>
							</div>
						</div>
					) : null}
				</>
			)}
		</>
	);
};

export default SettingVideo;
