import React, { useState } from "react";
import Modal from "antd/es/modal";
import Input from "antd/es/input";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { Option } from "antd/es/mentions";
import styled from "../../styles/modals/modals.module.scss";
import { editorAPI } from "../../api/api";
import { getPageType, setTemplates } from "../../redux/actions/templatesAction";
import {
	getActivePage,
	getPageTypeData,
} from "../../redux/selectors/templatesSelectors";
import { setNamePage } from "../../redux/reducers/editorReducer";

export default function ModalNamePage({
	open,
	handleClose,
	setMessages,
	title,
	namePage = "",
	id,
}) {
	const pageType = useSelector((state) => getPageTypeData(state));
	const activePage = useSelector((state) => getActivePage(state));
	const dispatch = useDispatch();

	const [name, setName] = useState("");

	const selectPageTypeHandle = (value) => dispatch(getPageType(value));

	const sendDataTemplate = async () => {
		if (!name) {
			setMessages(["Введите название шаблона"]);
			return;
		}
		const send = namePage ? editorAPI.changeName : editorAPI.create;
		const { api_success, api_messages } = await send(name, id);
		if (api_success) {
			setName("");
			handleClose();
			dispatch(setTemplates(activePage));
			dispatch(setNamePage(name));
		}
		setMessages(api_messages);
	};

	const clickEnter = (e) => {
		if (e.keyCode == 13) {
			e.preventDefault();
			sendDataTemplate();
		}
	};

	return (
		<div>
			<Modal
				visible={open}
				onOk={sendDataTemplate}
				onCancel={handleClose}
				title={title}
				okText={namePage ? "Изменить" : "Создать"}
				cancelText="Отмена"
				closeIcon={<span className="f-icon icon-cross fs-17" />}
			>
				<form noValidate autoComplete="off" onKeyDown={clickEnter}>
					<div className={styled.inputLarge}>
						<div
							style={{
								width: "100%",
								marginBottom: "30px",
							}}
						>
							<h4>Название</h4>
							<Input
								placeholder="Название"
								defaultValue={namePage}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>

						{namePage && (
							<div
								style={{
									width: "100%",
									marginBottom: "30px",
								}}
							>
								<h4>Выберите тип страницы</h4>
								<Select
									style={{
										width: "100%",
									}}
									defaultValue={pageType}
									size="middle"
									onChange={(e, target) => {
										selectPageTypeHandle(target.value);
										handleClose();
									}}
								>
									<Option value="page">Страница</Option>
									<Option value="creative">
										Слайдер Saint Laurent
									</Option>
								</Select>
							</div>
						)}
					</div>
				</form>
			</Modal>
		</div>
	);
}
