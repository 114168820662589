import React, { useEffect, useState } from "react";

function UseCountdown(targetDate) {
	const [expireDate, setExpireDate] = useState();
	const [countdownTime, setCountdownTime] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	});

	useEffect(() => {
		setExpireDate(targetDate);
	}, [targetDate]);

	useEffect(() => {
		const timeInterval = setInterval(() => {
			const countdownDateTime = new Date(expireDate).getTime();
			const currentTime = new Date().getTime();
			const remainingDayTime = countdownDateTime - currentTime;

			const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
			const totalHours = Math.floor(
				(remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
			);
			const totalMinutes = Math.floor(
				(remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
			);
			const totalSeconds = Math.floor(
				(remainingDayTime % (1000 * 60)) / 1000
			);

			setCountdownTime({
				days: totalDays,
				hours: totalHours,
				minutes: totalMinutes,
				seconds: totalSeconds,
			});

			if (remainingDayTime < 0) {
				clearInterval(timeInterval);
				setExpireDate(false);
			}
		}, 1000);

		return () => {
			clearInterval(timeInterval);
		};
	});

	return countdownTime;
}

export default UseCountdown;
