import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import BorderActive from "../../workFields/BorderActive";
import { getLang, getTheme } from "../../../../redux/selectors/editorSelectors";
import { useBorderActive } from "../../../../hooks/useBorderActive";
import styled from "../../../../styles/editorPage/workField.module.scss";

const ButtonUnderline = ({
	mainId,
	blockId,
	component,
	isActivePage,
	collection,
}) => {
	const lang = useSelector((state) => getLang(state));
	const theme = useSelector(getTheme);

	let navigate = useNavigate();

	const { width, height, isHide, ref, resize, idActive, selectThisComponent } =
		useBorderActive(component, [mainId, blockId, component.id]);

	const setActiveOrLinkTo = (event) => {
		if (!isHide) {
			resize();
			selectThisComponent(event);
		} else if (component?.link?.isActive) {
			if (component?.link?.out) {
				window.open(component.link.path);
			} else {
				let arr = component.link.path.split(".com");
				navigate(arr[1].slice(7));
			}
		}
	};
	return (
		<div
			className={styled.frontSpaceWrapper}
			style={{
				alignSelf: component?.styles.alignSelf,
			}}
		>
			<div
				className="p-relative"
				style={{
					margin: component.styles.margin.join(" "),
				}}
			>
				{!isHide &&
					idActive.length === 3 &&
					component.id === idActive[2] &&
					!isActivePage && (
						<BorderActive
							width={width}
							height={height}
							id={[mainId, blockId, component.id]}
							large
							collection={collection}
							container
						/>
					)}
				<button
					ref={ref}
					className={styled.buttonUnderline}
					style={{
						fontWeight: component.styles.fontWeight,
						fontSize: component.styles.fontSize,
						fontStyle: component.styles.fontStyle || "normal",
						fontFamily: component.styles.fontFamily,
						borderColor:
							theme === "light"
								? component.styles.borderColor
								: component.styles.borderColorDark,
						color:
							theme === "light"
								? component.styles.color
								: component.styles.colorDark,
					}}
					onClick={setActiveOrLinkTo}
				>
					{component.text[lang]}
				</button>
			</div>
		</div>
	);
};

export default ButtonUnderline;
