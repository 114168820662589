import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router";
import styled from "../../../../styles/editorPage/workField.module.scss";
import BorderActive from "../../workFields/BorderActive";
import DashPlayer from "../../workFields/DashPlayer";
import { useBorderActive } from "../../../../hooks/useBorderActive";

const VideoContainer = ({ mainId, component, collection, isActivePage }) => {
	const [refInView, inView] = useInView({ threshold: 0.1 });

	let navigate = useNavigate();

	const { width, height, isHide, resize, idActive, selectMainComponent, ref } =
		useBorderActive(component, [component?.id]);

	const setActiveOrLinkTo = () => {
		if (isHide) {
			if (component?.link?.isActive) {
				if (component?.link?.out) {
					window.open(component.link.path);
				} else {
					let arr = component.link.path.split(".com");
					navigate(arr[1].slice(7));
				}
			}
		}
	};

	const select = (e) => {
		resize();
		selectMainComponent(e);
	};

	const getAnimateFlash = () => {
		if (component.animate === undefined || component.animate.showFlash) {
			return inView ? styled.mosaicCardShow : styled.mosaicCardHide;
		}
		return "";
	};

	return (
		<div className="p-relative w-100">
			{!isHide &&
				idActive.length === 1 &&
				idActive[0] === component?.id &&
				!isActivePage && (
					<BorderActive
						width={width}
						height={height}
						id={[mainId]}
						collection={collection}
						btnDown
						container
					/>
				)}
			<div ref={refInView} className={`${getAnimateFlash()}`}>
				<div
					onMouseDown={select}
					onContextMenu={(e) => e.preventDefault()}
					ref={ref}
					onClick={setActiveOrLinkTo}
					style={{
						marginBottom: component?.styles?.marginBottom,
					}}
				>
					<DashPlayer
						src={component.video}
						poster={component?.image?.url}
						autoplay={component?.isPlay}
						component={component.components[0]}
						mainId={mainId}
						isActivePage={isActivePage}
					/>
				</div>
			</div>
		</div>
	);
};

export default VideoContainer;
