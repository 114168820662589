import React, { useEffect, useState } from "react";
import CountDownTimeDisplay from "./CountDownTimeDisplay";
import useCountdown from "./useCountdown";
import styled from "../../../../../styles/editorPage/workField.module.scss";
import { useBorderActive } from "../../../../../hooks/useBorderActive";
import BorderActive from "../../../workFields/BorderActive";

function MosaicCountDownTimer(props) {
	const { mainId, blockId, component, isActivePage, collection } = props;

	const countdown = useCountdown(
		`${component?.datePiker.date} ${component?.datePiker.time}`
	);
	/*  const lang = useSelector(state => getLang(state)); */
	const { width, height, isHide, ref, resize, idActive, selectThisComponent } =
		useBorderActive(component, [mainId, blockId, component.id]);

	const setActive = (event) => {
		if (!isHide) {
			resize();
			selectThisComponent(event);
		}
	};

	return (
		<div
			className={styled.frontSpaceWrapper}
			style={{
				alignSelf: component?.styles.alignSelf,
			}}
		>
			<div
				className="p-relative"
				style={{
					margin: component?.styles.margin.join(" "),
				}}
			>
				{!isHide &&
					idActive.length === 3 &&
					component.id === idActive[2] &&
					!isActivePage && (
						<BorderActive
							width={width}
							height={height}
							id={[mainId, blockId, component.id]}
							large
							collection={collection}
							container
						/>
					)}
				<div
					className={styled.mosaic_countdown_container}
					ref={ref}
					onClick={setActive}
				>
					<CountDownTimeDisplay
						value={countdown.days}
						type="Дней"
						isDot
						styles={component?.styles}
					/>
					<CountDownTimeDisplay
						value={countdown.hours}
						type="Часов"
						isDot
						styles={component?.styles}
					/>
					<CountDownTimeDisplay
						value={countdown.minutes}
						type="Минут"
						isDot
						styles={component?.styles}
					/>
					<CountDownTimeDisplay
						value={countdown.seconds}
						type="Секунд"
						styles={component?.styles}
					/>
				</div>
			</div>
		</div>
	);
}

export default MosaicCountDownTimer;
