import { combineReducers } from "redux";
import templatesReducer from "./templatesReducer";
import editorReducer from "./editorReducer";
import imagesReducer from "./imagesReducer";
import errorsReducer from "./errorsReducer";
import triggerReducer from "./triggerReducer";

export default combineReducers({
	templatesReducer,
	editorReducer,
	imagesReducer,
	errorsReducer,
	triggerReducer,
});
